<style lang='scss' scoped>
  .btn-nav {
    max-width: 240px;
    text-transform: uppercase;
    width: 100%;
  }
  .card {
    padding: 0;
    h1, h2, h3, h4, h5 {
      font-weight: bold;
    }
  }
  .col-nav {
    border-left: black 1px solid;
    text-align:center;
  }
  .home {
    min-height: 100%;
    min-width: 100%;
    // background: url("~@/assets/pbp_bg.jpg") no-repeat center center fixed;
    background: url("~@/assets/pbp_bg.jpg") no-repeat 50% fixed;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    overflow: auto;
  }
  .home-header {
    background-color: #44441cc0;
    color:white;
  }
  .home-header, .home-body, home-footer {
    padding: 20px;
    text-align: center;
  }
  .home-body {
    background-color: #ffffffc0;
    font-size: .90em;
    text-align: left;
  }
  .home-footer {
    color:white;
  }
  .home-footer-row {
    background-color: #44441cc0;
    margin: 0;
  }
  .is-signed-in {
    height: calc(100% - 74px) !important;
    // height: 100% !important;
  }
  // .is-signed-in-home {
  //   height: calc(100% - 26px) !important;
  //   // height: 0px;
  // }
  .is-signed-out {
    height: calc(100% - 53px) !important;
    // height: 100% !important;
    // width: 100%;
    // height: auto;
  }
  .logo-round {
    filter: drop-shadow(5px 5px 5px black);
    width: 146px;
  }
  .logo-sgsf {
    filter: drop-shadow(5px 5px 5px black);
    height: 146px;
  }
  .logo-talltimbers {
    filter: drop-shadow(5px 5px 5px black);
    width: 288px;
  }
  .logo-texas {
    filter: drop-shadow(5px 5px 5px black);
  }
  @keyframes logo-spin-animation {
    0% {
      height: 0px;
      transform: rotate(0deg)
    }
    100% {
      height: 275px;
      transform: rotate(360deg)
    }
  }
  @keyframes logo-bounce-animation {
      25% {
        transform: translateY(-10px);
      }
      50% {
        transform: translateY(0);
      }
      75%  {
        transform: translateY(-5px);
      }
      100% { transform: translateY(0); }
  }
  .logo {
    filter: drop-shadow(5px 5px 5px black);
    height: 275px;
    margin-top: 25px;
    position: relative;
    text-align: center;
    z-index: 10;
  }
  .logo-spin {
    animation-name: logo-spin-animation;
    animation-delay: 0s;
    animation-duration: 3s;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    z-index: 10;
  }
  .logo-bounce {
    animation-name: logo-bounce-animation;
    animation-delay: 3s;
    animation-duration: 2s;
    animation-iteration-count: 1;
    z-index: 10;
  }
  .logo-container {
    height: 300px;
    position: relative;
    text-align: center;
    z-index: 20;
  }
  .text-container {
    position:relative;
    top:-24px;
    z-index: 5;
  }
</style>
<template>
  <div class="home" v-bind:class="{ 'is-signed-in': isSignedIn, 'is-signed-out': !isSignedIn}">
    <div class='logo-container'>
      <img class='logo' alt="SGSF logo" src="../assets/sgsf.svg">
    </div>
    <div class='row text-container me-0'>
      <div class='col'></div>
      <div class='col-10'>
        <div class='border border-dark shadow-lg'>
          <div class='home-header'>
            <h1>Welcome to the Prescribed Burn Planner!</h1>
            <h5>A Burn Notification and Planning Application</h5>
          </div>
          <div class='home-body'>
            <div class='row'>
              <div class='col-9'>
                <h5>About This App</h5>

                <p>
                  <span><a href='https://goodfires.org' target='_blank'>Prescribed fire</a> is one of the most versatile and cost-effective tools landowners and land managers use to reduce hazardous fuel buildup.&nbsp;</span>
                  <span>Thus, providing increased protection to life, property, and natural resources.&nbsp;</span>
                  <span>It also helps control disease in young pine trees, improve wildlife habitat, manage rangeland, preserve endangered plant and animal species, and maintain fire-dependent ecosystems.</span>
                </p>

                <p>
                  <span>According to the <a href='https://www.stateforesters.org/wp-content/uploads/2018/12/2018-Prescribed-Fire-Use-Survey-Report-1.pdf' target='_blank'>2018 National Prescribed Fire Use Survey Report</a>, the thirteen states in the southeast region led the nation in total acres (7.6 million) treated with prescribed fire.&nbsp;</span>
                  <span>However, that same report documented that weather was the top impediment limiting prescribed fire use not only in the Southeast, but nationwide.</span>
                </p>

                <p>
                  <span>The Prescribed Burn Planner (PBP) application is a new tool developed to provide land managers with a decision support system that will allow the burn manager to:</span>
                  <ul>
                    <li>Enter desired weather parameters for a burn unit</li>
                    <li>Monitor and determine when predicted weather conditions fall within desired weather parameters (i.e. burning window)</li>
                    <li>Prioritize management objectives and burn units</li>
                    <li>Conduct the prescribed burn within the desired burning window</li>
                  </ul>
                </p>

                <p>
                  <span>NOTE: The PBP is currently available only to select state forestry agency personnel and key partners within the following states: Alabama, Arkansas, Florida, Kentucky, Louisiana, Mississippi, North Carolina, Oklahoma, South Carolina, Tennessee, Texas, and Virginia.</span>
                </p>

                <p>
                  <span>To begin planning your burns, sign in with a PBP account and navigate to the Burn Map.</span>
                </p>

              </div>
              <div class='col-3 col-nav'>
                <br/>
                <h5>Are you interested in prescribed burn planning?</h5>
                <br/>
                <p>
                  <router-link class="btn btn-primary btn-rounded btn-nav" to="/signup">Sign Up</router-link>
                </p>
                <p>
                  <router-link class="btn btn-primary btn-rounded btn-nav" to="/contact">Contact Us</router-link>
                </p>
                <p>
                  <a class="btn btn-primary btn-rounded btn-nav" href="https://youtu.be/zyl6V5Bo0eY" target="_blank">Tutorial Video</a>
                </p>
              </div>
            </div>
          </div>
          <div class='home-footer'>
            <div class='row home-footer-row'>
              <div class='col-3 d-flex align-items-center justify-content-center'>
                <div class='card-body'>
                  <div class='d-flex justify-content-center'>
                    <a href='https://www.fdacs.gov/Forest-Wildfire/Wildland-Fire/Prescribed-Fire' target="_blank">
                      <img src='../assets/ffs.png' alt='Florida Forest Service' class='logo-round'/>
                    </a>
                  </div>
                  <!-- <div class='d-flex justify-content-center pt-3'>
                    <a href='https://www.fdacs.gov/Forest-Wildfire/Wildland-Fire/Prescribed-Fire' target="_blank" class='text-reset text-decoration-none'>FDACS</a>
                  </div> -->
                </div>
              </div>
              <!--
              <div class='col-2 d-flex align-items-center justify-content-center'>
                <div class='card-body'>
                  <div class='d-flex justify-content-center'>
                    <a href='https://southernforests.org/fire/prescribed-fire-in-the-south/' target="_blank">
                      <img src='../assets/sgsf.svg' alt='Southern Group of State Foresters' class='logo-sgsf'/>
                    </a>
                  </div>
                  <div class='d-flex justify-content-center pt-3'>
                    <a href='https://southernforests.org/fire/prescribed-fire-in-the-south/' target="_blank" class='text-reset text-decoration-none'>SGSF</a>
                  </div>
                </div>
              </div>
              -->
              <div class='col-3 d-flex align-items-center justify-content-center'>
                <div class='card-body'>
                  <div class='d-flex justify-content-center'>
                    <a href='https://www.floridastateparks.org/PrescribedFire' target="_blank">
                      <img src='../assets/fdep_new_too.png' alt='Florida Department of Environmental Protection' class='logo-round'/>
                    </a>
                  </div>
                  <!-- <div class='d-flex justify-content-center pt-3'>
                    <a href='https://www.floridastateparks.org/PrescribedFire' target="_blank" class='text-reset text-decoration-none'>FDEP</a>
                  </div> -->
                </div>
              </div>
              <div class='col-3 d-flex align-items-center justify-content-center'>
                <div class='card-body'>
                  <div class='d-flex justify-content-center'>
                    <a href='https://talltimbers.org/' target="_blank">
                      <img src='../assets/talltimbers.png' alt='Tall Timbers' class='logo-talltimbers'/>
                    </a>
                  </div>
                  <!-- <div class='d-flex justify-content-center pt-3'>
                    <a href='https://talltimbers.org/' target="_blank" class='text-reset text-decoration-none'>Tall Timbers</a>
                  </div> -->
                </div>
              </div>
              <div class='col-3 d-flex align-items-center justify-content-center'>
                <div class='card-body'>
                  <div class='d-flex justify-content-center'>
                    <a href='https://tfsweb.tamu.edu/default.aspx' target='_blank'>
                      <img src='../assets/tamfs.png' alt='Texas A and M Forest Service' class='logo-texas'/>
                    </a>
                  </div>
                  <!-- <div class='d-flex justify-content-center pt-3'>
                    <a href='https://tfsweb.tamu.edu/default.aspx' target='_blank' class='text-reset text-decoration-none'>TA&amp;M Forest Service</a>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class='col'></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Home',
  components: {},
  computed: {
    ...mapState('App', {
      auth: (state) => state.auth,
      version: (state) => state.version,
      build: (state) => state.build,
      showNavbarContainer: (state) => state.showNavbarContainer,
    }),
    isSignedIn() {
      let isSignedIn = false;
      if (this.auth !== null && this.auth.isUserSignedIn()) {
        isSignedIn = true;
      }
      return isSignedIn;
    },
  },
  mounted() {},
  methods: {},
};
</script>
