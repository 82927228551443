<style lang="scss" scoped>
  li {
    font-weight: bold;
  }
  .btn-primary {
    font-size: 0.875em;
  }
  .btn-burn-area-modal {
    width: 120px;
  }
  .btn-burn-area-modal-conditions {
    width: 200px;
  }
  .btn-close-small {
    font-size: 0.75rem;
  }
  .btn-green {
    // background-color: rgb(131, 146, 58) !important;
    background-color: rgb(60, 68, 28) !important;
  }
  .btn-red {
    background-color: #7F3F13 !important;
  }
  .card-header {
    background-color: #7F3F13 !important;
    color: white;
    font-weight: bold;
  }
  .container-recipients {
    height: 280px;
    overflow: auto;
  }
  .burn-condition-score-not-applicable {
    color: white;
    background-color: gray;
  }
  .burn-condition-score-low {
    color: white;
    background-color: rgb(184, 49, 49);
  }
  .burn-condition-score-medium {
    color: white;
    background-color: rgb(255, 234, 112);
  }
  .burn-condition-score-high {
    color: white;
    background-color: rgb(128, 145, 59);
  }
  .burn-condition-score-legend {
    display: inline-block;
    list-style: none;
    margin:0;
    padding:0;
    overflow:hidden;
  }
  .burn-condition-score-legend li{
    color: black;
    float:left;
    margin:0;
    padding: 0;
    list-style:none;
    width:40px;
    height:30px;
    text-align:center;
    line-height:30px;
    font-size: 0.875em;
    border: solid 1px #333;
    border-radius:3px;
    transition: all 0.5s;
  }
  .form-check-input:checked {
      background-color: rgb(127, 63, 20)!important;
      border-color: rgb(164, 141, 41)!important;
  }
  .form-control:focus{
    outline-color: rgb(164, 141, 41)!important;
  }
  .form-control-date {
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  }
  .form-label-small {
    font-size: 0.875rem;
  }
  .form-select{
    font-size: 0.75rem;
    option {
      font-size: 0.75rem;
    }
  }
  .form-switch {
    display: inline-block !important;
    padding-top: 10px;
  }
  .form-text-sm {
    font-size: 0.875rem;
  }
  .input-range {
    display: inline-block;
    font-size: 0.875em;
    width: 100%;
  }
  .lbl-burn a {
    color: rgb(127, 63, 20);
    font-size: .75em;
    text-decoration: none;
  }
  .link-edit {
    cursor: pointer;
    text-transform: uppercase;
    font-size: 0.75em !important;
  }
  .link-select-all, .link-unselect-all {
    color: rgb(127, 63, 20) !important;
  }
  .modal-dialog-bigger {
    max-width: 660px;
  }
  .modal-header {
    background-color: #7F3F13 !important;
  }
  .modal-title {
    color: white !important;
    font-size: 1em;
    font-weight: bold;
  }
  .modal-footer {
    justify-content: center !important;
  }
  .rounded-6 {
    border-radius: .5em;
  }
  .select-discrete {
    display: inline-block;
    width: 100%;
  }
  .server-error {
    overflow: auto;
  }
  #temperatureMin + span, #temperatureMax + span {
    font-size: .75em;
  }
  #relativeHumidityMin + span, #relativeHumidityMax + span {
    font-size: .75em;
  }
  #windSpeedMin + span, #windSpeedMax + span {
    font-size: .75em;
  }
  #windGustMin + span, #windGustMax + span {
    font-size: .75em;
  }
  #quantitativePrecipitationMin + span, #quantitativePrecipitationMax + span {
    font-size: .75em;
  }
  #transportWindSpeedMin + span, #transportWindSpeedMax + span {
    font-size: .75em;
  }
  #dispersionIndexMin + span, #dispersionIndexMax + span {
    font-size: .75em;
  }
  #mixingHeightMin + span, #mixingHeightMax + span {
    font-size: .75em;
  }
</style>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
    <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
    </symbol>
    <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
    </symbol>
    <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
      <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
    </symbol>
  </svg>
  <div id='deleteModal' class="modal fade" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header p-2 ps-3 pe-3">
          <h5 class="modal-title">Confirm Delete</h5>
          <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class='form-text'>
            <p>Are you sure you want to permanently delete this Burn Unit?</p>
          </div>
        </div>
        <div class="modal-footer col text-start">
          <button type="button" class="btn btn-primary btn-rounded btn-red btn-burn-area-modal" data-bs-dismiss="modal">Cancel</button>
          <button type="button" class="btn btn-primary btn-rounded btn-green btn-burn-area-modal" @click="onClickBurnDelete()">Yes</button>
        </div>
      </div>
    </div>
  </div>
  <div id='editSuccessModal' class="modal fade" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header p-2 ps-3 pe-3">
          <h5 class="modal-title">Save Completed</h5>
          <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class='form-label fw-bold'>The item was successfully saved.</div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary btn-rounded btn-green btn-burn-area-modal-conditions" data-bs-dismiss="modal">OK</button>
        </div>
      </div>
    </div>
  </div>
  <div id='editErrorModal' class="modal fade" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header p-2 ps-3 pe-3">
          <h5 class="modal-title">Save Failed!</h5>
          <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class='form-label text-danger fs-5 fw-bold'>The item could not be saved!</div>
          <div v-if='serverError && serverError.message' class='pt-2 server-error'>
            <span class='form-label fw-bold pe-2'>Message:</span>
            <span class='form-text'>{{serverError.message}}</span>
          </div>
          <div v-if='serverError && serverError.config'>
            <div v-if='serverError.config.method && serverError.config.url' class='pt-2 server-error'>
              <span class='form-label fw-bold pe-2'>Request:</span>
              <span class='form-text pe-1'>{{serverError.config.method}}</span>
              <span class='form-text'>{{serverError.config.url}}</span>
            </div>
            <div v-if='serverError.config.data' class='pt-2 server-error'>
              <span class='form-label fw-bold pe-2'>Data:</span>
              <span class='form-text'>{{serverError.config.data}}</span>
            </div>
          </div>
          <div v-if='serverError && serverError.stack' class='pt-2 server-error'>
            <span class='form-label fw-bold pe-2'>Stack:</span>
            <span class='form-text'>{{serverError.stack}}</span>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary btn-rounded btn-red btn-burn-area-modal-conditions" data-bs-dismiss="modal">OK</button>
        </div>
      </div>
    </div>
  </div>
  <div id='deleteErrorModal' class="modal fade" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header p-2 ps-3 pe-3">
          <h5 class="modal-title">Save Failed!</h5>
          <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class='form-label text-danger fs-5 fw-bold'>The item could not be deleted!</div>
          <div v-if='serverError && serverError.message' class='pt-2 server-error'>
            <span class='form-label fw-bold pe-2'>Message:</span>
            <span class='form-text'>{{serverError.message}}</span>
          </div>
          <div v-if='serverError && serverError.config'>
            <div v-if='serverError.config.method && serverError.config.url' class='pt-2 server-error'>
              <span class='form-label fw-bold pe-2'>Request:</span>
              <span class='form-text pe-1'>{{serverError.config.method}}</span>
              <span class='form-text'>{{serverError.config.url}}</span>
            </div>
            <div v-if='serverError.config.data' class='pt-2 server-error'>
              <span class='form-label fw-bold pe-2'>Data:</span>
              <span class='form-text'>{{serverError.config.data}}</span>
            </div>
          </div>
          <div v-if='serverError && serverError.stack' class='pt-2 server-error'>
            <span class='form-label fw-bold pe-2'>Stack:</span>
            <span class='form-text'>{{serverError.stack}}</span>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary btn-rounded btn-red btn-burn-area-modal-conditions" data-bs-dismiss="modal">OK</button>
        </div>
      </div>
    </div>
  </div>

  <VForm id='editConditionsModal' v-slot='{ handleSubmit }' class="modal fade" :validation-schema='conditionsModelSchema' @submit='onSubmitConditions' as='div' tabindex='-1'>
    <form id='conditionsForm' @submit='handleSubmit($event, onSubmitConditions)'>
      <div class="modal-dialog modal-dialog-bigger">
        <div class="modal-content">
            <div class="modal-header p-2 ps-3 pe-3">
              <h5 class="modal-title">Set Preferred Conditions</h5>
              <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div v-if='preferredConditionsModel' class="modal-body">
              <!-- <div class='col d-flex justify-content-center form-text'>Use the mouse to drag and drop conditions in order of relevancy</div> -->
              <ol>
                <li v-for='item in preferredConditionsModel' :key='item.name'>
                  <div class='card ms-2 mb-2 me-2 p-1 ps-2 pe-2 rounded-6'>

                    <div v-if="item.valType == 'discrete' && !(item.type === Array)" class='row'>
                      <div class='col-6 d-flex align-items-center'>
                        <span class='form-text form-text-sm ps-2'>
                          {{ item.label }}
                          <span v-if='item.required' class='text-danger'>*</span>
                        </span>
                      </div>
                      <div class='col-1 d-flex align-items-center'/>
                      <div class='col-5'>
                        <VField v-model='item.value' :id='item.name' :name='item.name' type='number' :step='item.step' class='form-control form-control-sm input-discrete'/>
                        <ErrorMessage class='text-danger' :name='item.name'/>
                      </div>
                    </div>

                    <div v-if="item.valType=='direction' && (item.type === Array)" class='row'>
                      <div class='row me-0 pe-0'>
                        <div class='col-6 d-flex form-text form-text-sm justify-content-start align-items-center pe-0 me-0'>
                          <span class='ps-2'>{{ item.label }}</span>
                          <span v-if='item.required' class='text-danger'>*</span>
                        </div>
                        <div class='col-6 d-flex justify-content-start align-items-center p-0 m-0'>
                          <a class="btn fw-bold link-edit link-select-all ps-0 ms-0" @click='onClickAllCheckboxesSelect(item)'>Select All</a>
                          <a class="btn fw-bold link-edit link-unselect-all ps-0 ms-0" @click='onClickAllCheckboxesUnselect(item)'>Unselect All</a>
                        </div>
                      </div>

                      <div class='row'>
                        <div class='col-6'></div>
                        <div class='col-6'>
                          <div class='row'>
                            <div class='col-4'>
                              <input v-model='item.value' :id='item.name + "NW"' value='NW' class='form-check-input' type='checkbox'/>
                              <label class='ps-1 form-check-label' :for='item.name + "NW"'>NW</label>
                            </div>
                            <div class='col-4'>
                              <input v-model='item.value' :id='item.name + "N"' value='N' class='form-check-input' type='checkbox'/>
                              <label class='ps-1 form-check-label' :for='item.name + "N"'>N</label>
                            </div>
                            <div class='col-4'>
                              <input v-model='item.value' :id='item.name + "NE"' value='NE' class='form-check-input' type='checkbox'/>
                              <label class='ps-1 form-check-label' :for='item.name + "NE"'>NE</label>
                            </div>
                          </div>
                          <div class='row'>
                            <div class='col-4'>
                              <input v-model='item.value' :id='item.name + "W"' value='W' class='form-check-input' type='checkbox'/>
                              <label class='ps-1 form-check-label' :for='item.name + "W"'>W</label>
                            </div>
                            <div class='col-4'/>
                            <div class='col-4'>
                              <input v-model='item.value' :id='item.name + "S"' value='E' class='form-check-input' type='checkbox'/>
                              <label class='ps-1 form-check-label' :for='item.name + "E"'>E</label>
                            </div>
                          </div>
                          <div class='row'>
                            <div class='col-4'>
                              <input v-model='item.value' :id='item.name + "SW"' value='SW' class='form-check-input' type='checkbox'/>
                              <label class='ps-1 form-check-label' :for='item.name + "SW"'>SW</label>
                            </div>
                            <div class='col-4'>
                              <input v-model='item.value' :id='item.name + "S"' value='S' class='form-check-input' type='checkbox'/>
                              <label class='ps-1 form-check-label' :for='item.name + "S"'>S</label>
                            </div>
                            <div class='col-4'>
                              <input v-model='item.value' :id='item.name + "SE"' value='SE' class='form-check-input' type='checkbox'/>
                              <label class='ps-1 form-check-label' :for='item.name + "SE"'>SE</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div v-if="item.valType=='range'" class='row'>
                      <div class='col-6 d-flex align-items-center'>
                        <span class='form-text form-text-sm ps-2'>
                          {{ item.label }}
                          <span v-if='item.required' class='text-danger'>*</span>
                        </span>
                      </div>
                      <div class='col-1 d-flex align-items-center form-text form-text-sm'>Min:</div>
                      <div class='col-2'>
                        <VField v-model='item.value.min' :id='item.name + "Min"' :name='item.name + "Min"' as='input' type='number' :step='item.step' class='form-control form-control-sm input-range'/>
                        <ErrorMessage class='text-danger' :name='item.name + "Min"'/>
                      </div>
                      <div class='col-1 d-flex align-items-center form-text form-text-sm'>Max:</div>
                      <div class='col-2'>
                        <VField v-model='item.value.max' :id='item.name + "Max"' :name='item.name + "Max"' as='input' type='number' :step='item.step' class='form-control form-control-sm input-range'/>
                        <ErrorMessage class='text-danger' :name='item.name + "Max"'/>
                      </div>
                    </div>

                  </div>
                </li>
              </ol>
              <div class='col d-flex justify-content-center form-text fst-italic text-danger'>
                <span class='fs-6'>*</span>
                <span class='ps-1 fs-6'>Fields marked with an asterisk are required</span>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary btn-rounded btn-red btn-burn-area-modal-conditions" data-bs-dismiss="modal">Cancel</button>
              <button type='submit' value='Submit' class="btn btn-primary btn-rounded btn-green btn-burn-area-modal-conditions" form='conditionsForm' :disabled='isSubmitPreferredConditionsDisabled()'>Set Conditions</button>
            </div>
        </div>
      </div>
    </form>
  </VForm>

  <VForm :validation-schema='burnModelSchema' v-slot='burnModelValidationResults'>
    <div id='editDetailsModal' class="modal fade" tabindex="-1">
      <div class="modal-dialog modal-dialog-bigger">
        <div class="modal-content">
          <div class="modal-header p-2 ps-3 pe-3">
            <h5 class="modal-title">Edit Details</h5>
            <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div v-if="burnModel && burnModel != null" class="modal-body container">
            <div class='row p-2'>
              <div class='col-3 text-end'><span class='form-label form-control-sm fw-bold'>Burn Name:</span></div>
              <div class='col'>
                <VField v-model='burnModel.name' name='name' class="form-control form-control-sm" placeholder='(Required, 50 characters max)' minlength="1" maxlength="50"/>
                <ErrorMessage class='text-danger' name='name'/>
              </div>
            </div>
            <div class='row p-2'>
              <div class='col-3 text-end'><span class='form-label form-control-sm fw-bold'>Date Last Burned:</span></div>
              <div class='col-9'>
                <datepicker v-model="burnModel.burnDate" name='burnDate' class='form-control-date form-control-sm' clearable input-format='MM/dd/yyyy' autocomplete="off"/>
              </div>
            </div>
            <div class='row p-2'>
              <div class='col-3 text-end'><span class='form-label form-control-sm fw-bold '>Burn Window:</span></div>
              <div class='col'>
                <div class="form-check form-check-inline form-control-sm">
                  <VField v-model='burnModel.burnWindow' id='burnWindow1' name="burnWindow" type="radio" class='form-check-input' value="Growing"/>
                  <label class='form-check-label' for='burnWindow1'>Growing</label>
                </div>
                <div class="form-check form-check-inline form-control-sm">
                  <VField v-model='burnModel.burnWindow' id='burnWindow2' name="burnWindow" type="radio" class='form-check-input' value="Dormant"/>
                  <label class='form-check-label' for='burnWindow2'>Dormant</label>
                </div>
                <div class="form-check form-check-inline form-control-sm">
                  <VField v-model='burnModel.burnWindow' id='burnWindow3' name="burnWindow" type="radio" class='form-check-input' value="Year-round"/>
                  <label class='form-check-label' for='burnWindow3'>Year-round</label>
                </div>
                <div><ErrorMessage class='text-danger' name="burnWindow"/></div>
              </div>
            </div>
            <div class='row p-2'>
              <div class='col-3 text-end'><span class='form-label form-control-sm fw-bold '>Planned Acres:</span></div>
              <div class='col'>
                <VField v-model='burnModel.plannedAcres' name='plannedAcres' type="number" class="form-control form-control-sm"/>
                <ErrorMessage class='text-danger' name='plannedAcres'/>
              </div>
            </div>
            <div class='row p-2'>
              <div class='col-3 text-end'><span class='form-label form-control-sm fw-bold '>State:</span></div>
              <div class='col'>
                <VField v-model='burnModel.state' as='select' name='state' class='form-select form-control-sm' aria-label='State'>
                  <option></option>
                  <option v-for='state in states' :key='state.fips' :value="state.name">{{ state.name }}</option>
                </VField>
                <ErrorMessage class='text-danger' name='state'/>
              </div>
            </div>
            <div class='row p-2'>
              <div class='col-3 text-end'><span class='form-label form-control-sm fw-bold'>Locality:</span></div>
              <div class='col'>
                <VField v-model='burnModel.locality' type="text" name='locality' class="form-control form-control-sm"/>
                <ErrorMessage class='text-danger' name='locality'/>
              </div>
            </div>
            <div class='row p-2'>
              <div class='col-3 text-end'><span class='form-label form-control-sm fw-bold'>Burn Status:</span></div>
              <div class='col'>
                <div class="form-check form-check-inline form-control-sm">
                  <VField v-model='burnModel.burnStatus' id='status1' name="status" type="radio" class='form-check-input' value="Active"/>
                  <label class='form-check-label' for='status1'>Active</label>
                </div>
                <div class="form-check form-check-inline form-control-sm">
                  <VField v-model='burnModel.burnStatus' id='status2' name="status" type="radio" class='form-check-input' value="Inactive"/>
                  <label class='form-check-label' for='status2'>Inactive</label>
                </div>
                <ErrorMessage class='text-danger' name="burnStatus"/>
              </div>
            </div>
            <div class='row p-2'>
              <div class='col-3 text-end' ><span class='form-label form-control-sm fw-bold '>Priority:</span></div>
              <div class='col'>
                <div class="form-check form-check-inline form-control-sm">
                  <VField v-model='burnModel.priority' id='priority1' name="priority" type="radio" class='form-check-input' value="Low"/>
                  <label class='form-check-label' for='priority1'>Low</label>
                </div>
                <div class="form-check form-check-inline form-control-sm">
                  <VField v-model='burnModel.priority' id='priority2' name="priority" type="radio" class='form-check-input' value="Normal"/>
                  <label class='form-check-label' for='priority2'>Normal</label>
                </div>
                <div class="form-check form-check-inline form-control-sm">
                  <VField v-model='burnModel.priority' id='priority3' name="priority" type="radio" class='form-check-input' value="High"/>
                  <label class='form-check-label' for='priority3'>High</label>
                </div>
                <ErrorMessage class='text-danger' name="priority"/>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary btn-rounded btn-burn-area-modal" data-bs-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-primary btn-rounded btn-burn-area-modal btn-green" :disabled='!burnModelValidationResults || !burnModelValidationResults.meta || !burnModelValidationResults.meta.valid || burnModelSaving' @click='onClickBurnSave'>
              <span v-if='!burnModelSaving'>Save</span>
              <span v-else>
                <span class="spinner-border spinner-border-sm pe-1" role="status" aria-hidden="true"></span>
                <span>Saving...</span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </VForm>

  <div id='editNotificationsModal' class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-bigger">
      <div class="modal-content">
        <div class="modal-header p-2 ps-3 pe-3">
          <h5 class="modal-title">Edit Notifications</h5>
          <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div v-if='burnModel' class="modal-body">
          <div class='row ps-4 pe-4'>
            <div class='col-4'>
              <label class="form-check-label me-2 text-nowrap text-truncate fw-bold form-label-small" for="flexSwitchCheckDefault">Daily Email:</label>
              <span class="form-check form-switch">
                <input v-model='burnModel.dailyEmail' class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
              </span>
              <label class='form-check-label me-2 text-nowrap text-truncate fw-bold form-label-small'>{{dailyEmailOnOff}}</label>
            </div>
            <div class='col text-end pt-2'>
              <div class='form-text text-nowrap'>Weather notifications will be sent via email.</div>
            </div>
          </div>
          <hr/>
          <div class='container container-recipients'>
            <div v-for='recipient in burnModel.recipients' :key='recipient.email' class='card card-body mb-2'>
              <div class='row'>
                <div class='col-5 d-flex justify-content-center fw-bold form-label form-label-small'>{{ recipient.name}}</div>
                <div class='col d-flex justify-content-center fw-bold form-label form-label-small'>{{ recipient.email }}</div>
                <button type="button" class="btn-close btn-close-small" aria-label="Close" @click='onClickRecipientDelete(recipient)'></button>
              </div>
            </div>
          </div>
          <!-- <hr/>
          <div class='row ps-4 pe-4'>
            <div class='col-3 fw-bold form-label form-label-small'>Name: </div>
            <div class='col'>
              <input
                v-model='recipientModel.name'
                name='name'
                type='text'
                placeholder='Name'
                autocapitalize="none"
                autocomplete="off"
                required
                class='form-control input-signup'
              />
            </div>
          </div> -->
          <!-- <div class='row ps-4 pe-4 mt-3'>
            <div class='col-3 fw-bold form-label form-label-small'>Email Address: </div>
            <div class='col'>
              <input
                v-model='recipientModel.email'
                name='email'
                type='email'
                placeholder='name@host.com'
                autocapitalize="none"
                autocomplete="off"
                required
                class='form-control input-signup'
              />
            </div>
          </div> -->
          <!-- <div class='row mt-3'>
            <div class='col-12 d-flex justify-content-center'>
              <a :class='{ disabled: !recipientModelIsValid }' class='btn btn-primary btn-rounded btn-green ps-5 pe-5' :href="recipientHref" @click="onClickAddSubscriber()">Add Subscriber</a>
            </div>
          </div> -->
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary btn-rounded btn-burn-area-modal" data-bs-dismiss="modal">Cancel</button>
          <button type="button" class="btn btn-primary btn-rounded btn-burn-area-modal btn-green" :disabled='burnModelSaving' @click='onClickBurnSave'>
            <span v-if='!burnModelSaving'>Save</span>
            <span v-else>
              <span class="spinner-border spinner-border-sm pe-1" role="status" aria-hidden="true"></span>
              <span>Saving...</span>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div id='addBurnAreaSubscriberModal' class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-bigger">
      <div class="modal-content">
        <div class="modal-header p-2 ps-3 pe-3">
          <h5 class="modal-title">Add Subscriber</h5>
          <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div v-if='burnModel' class="modal-body">
          <div class='row ps-4 pe-4'>
            <div class='col-3 fw-bold form-label form-label-small'>Name: </div>
            <div class='col'>
              <input
                v-model='recipientModel.name'
                name='name'
                type='text'
                placeholder='Name'
                autocapitalize="none"
                autocomplete="off"
                required
                class='form-control input-signup'
              />
            </div>
          </div>
          <div class='row ps-4 pe-4 mt-3'>
            <div class='col-3 fw-bold form-label form-label-small'>Email Address: </div>
            <div class='col'>
              <input
                v-model='recipientModel.email'
                name='email'
                type='email'
                placeholder='name@host.com'
                autocapitalize="none"
                autocomplete="off"
                required
                class='form-control input-signup'
              />
            </div>
          </div>
          <div class='row mt-3'>
            <div class='col-12 d-flex justify-content-center'>
              <a :class='{ disabled: !recipientModelIsValid }' class='btn btn-primary btn-rounded btn-green ps-5 pe-5' :href="recipientHref" @click="onClickAddSubscriber()">Add Subscriber</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if='!formLoading' class='container-fluid mt-2'>
    <div class="container-fluid mt-3">
      <div class='row align-items-start mb-3'>
        <div class='col'>
          <div class='text-secondary'>
            <span>{{ myBurn.name }}</span>
            <a class='btn p-0 m-0 ps-2' role='button' @click='onClickDetailsEdit'>
              <font-awesome-icon icon="pen" class='fa-1x text-secondary'/>
            </a>
          </div>
          <div class='lbl-burn'>
            <router-link :to='getReturnLinkTo()'>
              <font-awesome-icon icon="chevron-left" class='fa-1x'/>
              <span class='ms-1'>Back to Burn List</span>
            </router-link>
          </div>
        </div>
        <div class='col text-end'>
          <button type='button' id='btnDelete' class="btn btn-primary btn-rounded shadow" data-bs-toggle='modal' data-bs-target="#deleteModal">Delete Burn Unit</button>
        </div>
      </div>
    </div>
    <div class='container-fluid'>
      <div class='row mb-3'>
        <div class='col border rounded p-0 me-3 shadow'>
          <BurnAreaMap v-if='myBurn' :id='myBurn.id' :readyToBurn='readyToBurn' />
        </div>
        <div class='col card shadow'>
          <div class='card-body m-0 p-0'>
            <div class='row card-header card-header-details'>
              <div class='col fw-bold'>Details</div>
            </div>
            <BurnAreaDetails v-if='myBurn' :id='myBurn.id' @edit-burn-area-details="editBurnAreaDetailsHandler()"/>
            <div class='row card-body m-0 p-0 pt-1 ps-3 pe-3'>
              <hr/>
            </div>
            <BurnAreaNotifications v-if='myBurn' :id='myBurn.id' @add-burn-area-subscriber="addBurnAreaSubscriberHandler()" @edit-burn-area-notifications="editBurnAreaNotificationsHandler()"/>
          </div>
        </div>
      </div>
      <div class='card-body m-0'>
        <div class='row card-header card-header-details pt-0 pb-0'>
          <div class='col-3 d-flex align-items-center fw-bold'>
            Weather/Fire Behavior Factors
          </div>
          <div class='col-4'>
            <div class='row'>
              <div class='col d-flex align-items-center justify-content-end'>
                <div style="display:inline;">Burn Probability Score Legend:</div>
              </div>
              <div class='col pt-2'>
                <ul class='burn-condition-score-legend'>
                  <li class='shadow me-1 burn-condition-score-low'>Low</li>
                  <li class='shadow me-1 burn-condition-score-medium'>Med</li>
                  <li class='shadow burn-condition-score-high'>High</li>
                  <!-- <li class='burn-condition-score-not-applicable'>N/A</li> -->
                </ul>
              </div>
            </div>
          </div>
          <div class='col-3 d-flex align-items-center fw-bold justify-content-end' >
            <span class="">Last Updated: {{lastUpdated}}</span>
          </div>
          <div class='col-2 d-flex align-items-center fw-bold justify-content-end' >
            <span class="btn-primary link-edit" @click='onClickConditionsEdit'>Update Conditions</span>
          </div>
        </div>
        <div class='row border rounded mb-2'>
          <BurnAreaConditions v-if='myBurn' :id='myBurn.id'/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable newline-per-chained-call, no-param-reassign */
import { mapState } from 'vuex';
import { Modal } from 'bootstrap';
import { Form as VForm, Field as VField, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import Datepicker from 'vue3-datepicker';
import { WKT } from 'ol/format';
import BurnAreaHelper from '@/mixins/burnAreaHelper';
import BurnScoreHelper from '@/mixins/burnScoreHelper';
import DateHelper from '@/mixins/dateHelper';
import DirectionHelper from '@/mixins/directionHelper';
import PreferredConditionHelper from '@/mixins/preferredConditionHelper';
import BurnAreaDetails from '../components/BurnArea/BurnAreaDetails.vue';
import BurnAreaMap from '../components/BurnArea/BurnAreaMap.vue';
import BurnAreaNotifications from '../components/BurnArea/BurnAreaNotifications.vue';
import BurnAreaConditions from '../components/BurnArea/BurnAreaConditions.vue';

// https://www.npmjs.com/package/vue3-datepicker

export default {
  name: 'BurnArea',
  mixins: [
    BurnAreaHelper,
    BurnScoreHelper,
    DateHelper,
    DirectionHelper,
    PreferredConditionHelper,
  ],
  props: {},
  components: {
    BurnAreaDetails,
    BurnAreaNotifications,
    BurnAreaMap,
    BurnAreaConditions,
    Datepicker,
    VForm,
    VField,
    ErrorMessage,
  },
  computed: {
    ...mapState(['userName', 'userEmail', 'userFirstName', 'userLastName']),
    ...mapState('App', {
      states: (state) => state.states,
    }),
    ...mapState('BurnArea', {
      myBurn: (state) => state.myBurn,
      myBurnStats: (state) => state.myBurnStats,
      serverError: (state) => state.serverError,
    }),
    dailyEmailOnOff() {
      if (!this.burnModel) return '';
      if (this.burnModel.dailyEmail) return 'ON';
      return 'OFF';
    },
    lastUpdated() {
      console.debug('Enter lastUpdated()');

      if (!this.myBurnStats || this.myBurnStats.length === 0) {
        return 'N/A';
      }

      const myBurnStat = this.myBurnStats[0];

      if (!myBurnStat.fields || !myBurnStat.fields.runDate) {
        return 'N/A';
      }

      console.debug('Exit lastUpdated()');
      return `${new Date(this.myBurnStats[0].fields.runDate).toLocaleTimeString()}, ${new Date(this.myBurnStats[0].fields.runDate).toLocaleDateString()}`;
    },
    recipientHref() {
      console.debug('Enter recipientHref()');

      console.debug('myBurn: ', this.myBurn);
      console.debug('burnModel: ', this.burnModel);

      const feature = new WKT().readFeature(
        this.myBurn.fields.Geometry.wkt,
        {
          dataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:4326',
        },
      );
      console.debug('feature: ', feature);

      // burn.googleUrl = `https://www.google.com/maps/search/?api=1&query=${burn.coordinates[1]},${burn.coordinates[0]}`;/
      const coordinates = feature.getGeometry().getCoordinates().map((e) => {
        let coordinate = e;
        console.debug('coordinate: ', coordinate);
        coordinate *= 10000;
        console.debug('coordinate: ', coordinate);
        coordinate = Math.round(coordinate);
        coordinate *= 0.0001;
        console.debug('coordinate: ', coordinate);
        return coordinate.toFixed(4);
      });
      console.debug('coordinates: ', coordinates);

      const subject = 'Prescribed Burn Planner Burn Unit Alert Subscription';

      const burn = encodeURIComponent(
        btoa(
          JSON.stringify(
            {
              id: this.burnModel.id,
              name: this.burnModel.name,
              locality: this.burnModel.locality,
              state: this.burnModel.state,
            },
          ),
        ),
      );
      const recipient = encodeURIComponent(btoa(JSON.stringify({ name: this.recipientModel.name, email: this.recipientModel.email })));

      let body = `Hello ${this.recipientModel.name}:%0D%0A%0D%0A`;
      body += `${this.userFirstName} ${this.userLastName} has sent you this link to subscribe to Prescribed Burn Unit '${this.burnModel.id} - ${this.burnModel.name}'.%0D%0A%0D%0A`;
      body += `This Burn Unit is located in ${this.myBurn.fields.locality}, ${this.myBurn.fields.state} at ${coordinates[1]},${coordinates[0]} `;
      body += `(https://www.google.com/maps/search/?api=1%26query=${coordinates[1]},${coordinates[0]})%0D%0A%0D%0A`;
      body += 'To receive alerts for this burn, use the link below for the Prescribed Burn Planner site and follow the instructions.%0D%0A%0D%0A';
      body += `${document.location.origin}/subscribe/${burn}/${recipient}`;

      const recipientHref = `mailto://${this.recipientModel.email}?subject=${subject}&body=${body}`;
      console.debug('recipientHref: ', recipientHref);

      console.debug('Exit recipientHref()');
      return recipientHref;
    },
    recipientModelIsDirty() {
      if (this.recipientModel.name !== null && this.recipientModel.name.length > 0) return true;
      if (this.recipientModel.email !== null && this.recipientModel.email.length > 0) return true;
      return false;
    },
    recipientModelIsValid() {
      console.debug('Enter recipientModelIsValid()');

      if (this.recipientModel.name === null || this.recipientModel.name.length === 0) {
        console.debug('recipientModel.name is null or empty');
        console.debug('Exit recipientModelIsValid()');
        return false;
      }
      if (this.recipientModel.email === null || this.recipientModel.email.length === 0) {
        console.debug('recipientModel.email is null or empty');
        console.debug('Exit recipientModelIsValid()');
        return false;
      }

      if (!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.recipientModel.email))) {
        console.debug(`recipientModel.email '${this.recipientModel.email}' is invalid`);
        console.debug('Exit recipientModelIsValid()');
        return false;
      }

      return true;
    },
  },
  data() {
    console.debug('Enter data()');

    const burnModelSchema = yup.object({
      name: yup.string().required('Burn Name is required').min(1).max(50),
      burnWindow: yup.string().required('Burn Window is required'),
      plannedAcres: yup.number('Required').moreThan(0).typeError('Please enter a number greater than 0'),
      state: yup.string().required('State is required'),
      locality: yup.string().required('Locality is required'),
    });

    // https://stackoverflow.com/questions/66795388/yup-validation-for-a-non-required-field
    // https://stackoverflow.com/questions/63230481/how-can-i-make-a-yup-number-accept-nullable-values

    const conditionsModelSchema = yup.object(
      {
        temperatureMin: yup.number().typeError('Must be numeric').nullable(true).transform((value) => {
          if (Number.isNaN(value)) return null;
          if (value === '') return null;
          return value;
        }),
        temperatureMax: yup.number(
          //
        ).typeError(
          'Must be numeric',
        ).nullable(
          true,
        ).moreThan(
          yup.ref('temperatureMin'),
          'Must be > Temp Min',
        ).transform((value) => {
          if (Number.isNaN(value)) return null;
          if (value === '') return null;
          return value;
        }),
        relativeHumidityMin: yup.number().typeError('Must be numeric').nullable(true).min(0, '0 or greater').transform((value) => {
          if (Number.isNaN(value)) return null;
          if (value === '') return null;
          return value;
        }),
        relativeHumidityMax: yup.number(
          //
        ).typeError(
          'Must be numeric',
        ).nullable(
          true,
        ).min(
          0,
          '0 or greater',
        ).moreThan(
          yup.ref('relativeHumidityMin'),
          'Must be > RH Min',
        ).transform((value) => {
          if (Number.isNaN(value)) return null;
          if (value === '') return null;
          return value;
        }),
        windSpeedMin: yup.number().typeError('Must be numeric').nullable(true).min(0, '0 or greater').transform((value) => {
          if (Number.isNaN(value)) return null;
          if (value === '') return null;
          return value;
        }),
        windSpeedMax: yup.number(
          //
        ).typeError(
          'Must be numeric',
        ).nullable(
          true,
        ).min(
          0,
          '0 or greater',
        ).moreThan(
          yup.ref('windSpeedMin'),
          'Must be > WS Min',
        ).transform((value) => {
          if (Number.isNaN(value)) return null;
          if (value === '') return null;
          return value;
        }),
        windGustMin: yup.number().typeError('Must be numeric').nullable(true).min(0, '0 or greater').transform((value) => {
          if (Number.isNaN(value)) return null;
          if (value === '') return null;
          return value;
        }),
        windGustMax: yup.number(
          //
        ).typeError(
          'Must be numeric',
        ).nullable(
          true,
        ).min(
          0,
          '0 or greater',
        ).moreThan(
          yup.ref('windGustMin'),
          'Must be > WG Min',
        ).transform((value) => {
          if (Number.isNaN(value)) return null;
          if (value === '') return null;
          return value;
        }),
        quantitativePrecipitationMin: yup.number().typeError('Must be numeric').nullable(true).min(0, '0 or greater').transform((value) => {
          if (Number.isNaN(value)) return null;
          if (value === '') return null;
          return value;
        }),
        quantitativePrecipitationMax: yup.number(
          //
        ).typeError(
          'Must be numeric',
        ).nullable(
          true,
        ).min(
          0,
          '0 or greater',
        ).moreThan(
          yup.ref('quantitativePrecipitationMin'),
          'Must be > QP Min',
        ).transform((value) => {
          if (Number.isNaN(value)) return null;
          if (value === '') return null;
          return value;
        }),
        transportWindSpeedMin: yup.number().typeError('Must be numeric').nullable(true).min(0, '0 or greater').transform((value) => {
          if (Number.isNaN(value)) return null;
          if (value === '') return null;
          return value;
        }),
        transportWindSpeedMax: yup.number(
          //
        ).typeError(
          'Must be numeric',
        ).nullable(
          true,
        ).min(
          0,
          '0 or greater',
        ).moreThan(
          yup.ref('transportWindSpeedMin'),
          'Must be > TWS Min',
        ).transform((value) => {
          if (Number.isNaN(value)) return null;
          if (value === '') return null;
          return value;
        }),
        dispersionIndexMin: yup.number(
          //
        ).typeError(
          'Numeric values only',
        ).integer(
          'Whole numbers only',
        ).nullable(
          true,
        ).min(
          1,
          'Must be >= 1',
        ).max(
          100,
          'Must be < 100',
        ).transform((value) => {
          if (Number.isNaN(value)) return null;
          if (value === '') return null;
          return value;
        }),
        dispersionIndexMax: yup.number(
          //
        ).typeError(
          'Numeric values only',
        ).integer(
          'Whole numbers only',
        ).nullable(
          true,
        ).min(
          2,
          'Must be > 1',
        ).max(
          100,
          'Must be <= 100',
        ).moreThan(
          yup.ref('dispersionIndexMin'),
          'Must be > DI Min',
        ).transform((value) => {
          if (Number.isNaN(value)) return null;
          if (value === '') return null;
          return value;
        }),
        mixingHeightMin: yup.number().typeError('Must be numeric').nullable(true).min(0, '0 or greater').max(10000, '10000 or less').transform((value) => {
          if (Number.isNaN(value)) return null;
          if (value === '') return null;
          return value;
        }),
        mixingHeightMax: yup.number(
          //
        ).typeError(
          'Must be numeric',
        ).nullable(
          true,
        ).min(
          0,
          '0 or greater',
        ).max(10000, '10000 or less').transform((value) => {
          if (Number.isNaN(value)) return null;
          if (value === '') return null;
          return value;
        }).moreThan(
          yup.ref('mixingHeightMin'),
          'Must be > MH Min',
        ),
      },
    );

    const preferredConditionsModel = (this.$store.getters['BurnArea/getPreferredConditionsMetadata']).map((e) => {
      const item = { ...e };
      if (item.valType === 'discrete') {
        if (item.type === Number) {
          item.value = null;
        }
        if (item.type === String) {
          item.value = null;
        }
      }
      if (item.valType === 'range') {
        item.value = { min: null, max: null };
      }
      if (item.valType === 'direction') {
        if (item.type === Array) {
          item.value = [];
        }
      }
      return item;
    });

    console.debug('Exit data()');
    return {
      addBurnAreaSubscriberModal: null,
      burnModel: null,
      burnModelSaving: false,
      burnModelSchema,
      burnModelValidationResults: null,
      conditionsModelSchema,
      conditionsModelValidationResults: null,
      deleteModal: null,
      deleteErrorModal: null,
      editConditionsModal: null,
      editDetailsModal: null,
      editNotificationsModal: null,
      editSuccessModal: null,
      editErrorModal: null,
      errors: null,
      formLoading: true,
      preferredConditionCounts: null,
      preferredConditionsModel,
      prevRoute: null,
      recipientModel: { name: null, email: null },
      readyToBurn: false,
    };
  },
  watch: {
    /* eslint-disable-next-line */
    myBurn: function() {
      console.debug('Enter watch.myBurn()');

      console.debug('Exit watch.myBurn()');
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  mounted() {

    this.addBurnAreaSubscriberModal = new Modal('#addBurnAreaSubscriberModal');
    this.deleteModal = new Modal('#deleteModal');
    this.deleteErrorModal = new Modal('#deleteErrorModal');
    this.editConditionsModal = new Modal('#editConditionsModal');
    this.editDetailsModal = new Modal('#editDetailsModal');
    this.editNotificationsModal = new Modal('#editNotificationsModal');
    this.editSuccessModal = new Modal('#editSuccessModal');
    this.editErrorModal = new Modal('#editErrorModal');

    this.formLoading = true;
    this.$store.dispatch('BurnArea/getBurn', this.$route.params.id).then(() => {
      this.formLoading = false;
    });

  },
  methods: {
    addBurnAreaSubscriberHandler() {
      console.debug('Enter addBurnAreaSubscriberHandler()');

      this.initializeBurnModel();
      this.addBurnAreaSubscriberModal.show();

      console.debug('Enter addBurnAreaSubscriberHandler()');
    },
    editBurnAreaDetailsHandler() {
      console.debug('Enter editBurnAreaDetailsHandler()');

      this.initializeBurnModel();
      this.editDetailsModal.show();

      console.debug('Exit editBurnAreaDetailsHandler()');
    },
    editBurnAreaNotificationsHandler() {
      this.initializeBurnModel();
      this.editNotificationsModal.show();
    },
    getOptionsSlice(options, start, end) {
      return options.slice(start, end);
    },
    getPreferredConditionDiscreteValue(name) {
      if (!this.myBurn.fields.preferredConditions) {
        return null;
      }
      const preferredCondition = this.myBurn.fields.preferredConditions.find((e) => e.name === name);
      if (!preferredCondition) {
        return null;
      }
      return preferredCondition.value;
    },
    getReturnLinkTo() {
      let to = null;
      if (this.prevRoute && this.prevRoute.name && (this.prevRoute.name === 'MyReadyToBurns' || this.prevRoute.name === 'MyPastBurns')) {
        to = { name: this.prevRoute.name };
      } else {
        to = { name: 'MyActiveBurns' };
      }
      return to;
    },
    initializeBurnModel() {
      console.debug('Enter initializeBurnModel()');

      let burnDate = null;

      if (this.myBurn.fields.burnDate && this.myBurn.fields.burnDate !== null) {
        burnDate = new Date(this.myBurn.fields.burnDate);
      }

      let recipients = [];

      if (this.myBurn.fields.recipients && this.myBurn.fields.recipients != null) {
        recipients = [...this.myBurn.fields.recipients];
      }

      this.burnModel = {
        id: this.myBurn.id,
        name: this.myBurn.name,
        burnDate,
        burnWindow: this.myBurn.fields.burnWindow,
        plannedAcres: this.myBurn.fields.plannedAcres,
        state: this.myBurn.fields.state,
        locality: this.myBurn.fields.locality,
        burnStatus: this.myBurn.fields.burnStatus,
        priority: this.myBurn.fields.priority,
        dailyEmail: this.myBurn.fields.dailyEmail,
        recipients,
      };
      console.debug('this.burnModel: ', this.burnModel);

      console.debug('this.preferredConditionsModel: ', this.preferredConditionsModel);

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.preferredConditionsModel.length; i++) {

        const preferredConditionModel = this.preferredConditionsModel[i];
        const preferredCondition = this.myBurn.fields.preferredConditions?.find((e) => e.name === preferredConditionModel.name);

        if (preferredCondition) {
          if (preferredConditionModel.valType === 'direction') {
            if (preferredConditionModel.type === Array && preferredCondition.value) {
              preferredConditionModel.value = preferredCondition.value.map((e) => e);
            }
          }
          if (preferredConditionModel.valType === 'discrete') {
            if (preferredConditionModel.type === Number) {
              if (preferredConditionModel.value !== null) {
                preferredConditionModel.value = Number(preferredCondition.value);
              }
            }
          }
          if (preferredConditionModel.valType === 'range') {
            preferredConditionModel.value = { min: null, max: null };
            if (preferredCondition.value) {
              preferredConditionModel.value = { min: preferredCondition.value.min, max: preferredCondition.value.max };
            }
          }
        } else {
          if (preferredConditionModel.valType === 'direction') {
            preferredConditionModel.value = [];
          }
          if (preferredConditionModel.valtype === 'discrete') {
            preferredConditionModel.value = null;
          }
          if (preferredConditionModel.valType === 'range') {
            preferredConditionModel.value = { min: null, max: null };
          }
        }
      }

      this.recipientModel = {
        name: null,
        email: null,
      };

      console.debug('Exit initializeBurnModel()');
    },
    isSubmitPreferredConditionsDisabled() {
      const windDirection = this.preferredConditionsModel.find((e) => e.name === 'windDirection');
      if (windDirection.value.length === 0) {
        return true;
      }
      return false;
    },
    onClickAllCheckboxesSelect(item) {
      console.debug('Enter onClickAllCheckboxesSelect()');

      console.debug('item: ', item);

      item.value = [...item.options];

      console.debug('Exit onClickAllCheckboxesSelect()');
    },
    onClickAllCheckboxesUnselect(item) {
      item.value = [];
    },
    onClickBurnDelete() {
      this.deleteModal.hide();
      this.$store.dispatch('BurnArea/deleteBurn', this.$route.params.id).then(() => {
        if (this.serverError === null) {
          this.$router.push({ name: 'MyActiveBurns' });
        } else {
          this.deleteErrorModal.show();
        }
      });
    },
    onClickBurnSave() {
      console.debug('Enter onClickBurnSave()');

      this.burnModelSaving = true;

      const model = {
        id: this.myBurn.id,
        name: this.burnModel.name,
        fields: {
          Geometry: this.myBurn.fields.Geometry,
          burnDate: this.burnModel.burnDate,
          burnStatus: this.burnModel.burnStatus,
          burnWindow: this.burnModel.burnWindow,
          locality: this.burnModel.locality,
          plannedAcres: Number(this.burnModel.plannedAcres),
          priority: this.burnModel.priority,
          state: this.burnModel.state,
          dailyEmail: this.burnModel.dailyEmail,
          recipients: [...this.burnModel.recipients],
          preferredConditions: this.preferredConditionsModel,
        },
      };
      console.debug('model: ', model);

      this.$store.dispatch('BurnArea/putBurn', model).then(() => {
        this.burnModelSaving = false;
        this.editDetailsModal.hide();
        this.editNotificationsModal.hide();
        if (this.serverError === null) {
          this.editSuccessModal.show();
        } else {
          this.editErrorModal.show();
        }
      }).catch((exception) => {
        console.error('Enter BurnArea/putBurn.catch()');
        console.error('exception: ', exception);
        console.error('Exit BurnArea/putBurn.catch()');
      });

      console.debug('Exit onClickBurnSave()');
    },
    onClickConditionsEdit() {
      // https://vee-validate.logaretm.com/v4/tutorials/basics
      // https://vee-validate.logaretm.com/v4/guide/components/handling-forms#resetting-forms-after-submit
      this.initializeBurnModel();
      this.editConditionsModal.show();
    },
    onClickDetailsEdit() {
      this.initializeBurnModel();
      this.editDetailsModal.show();
    },
    onClickAddSubscriber() {
      console.debug('Enter onClickAddSubscriber()');

      this.addBurnAreaSubscriberModal.hide();

      console.debug('Exit onClickAddSubscriber()');
    },
    onClickRecipientAdd() {
      console.debug('Enter onClickRecipientAdd()');

      // this.burnModel.recipients = this.burnModel.recipients.filter((e) => e.email !== this.recipientModel.email);
      // this.burnModel.recipients.push({ ...this.recipientModel });
      // this.burnModel.recipients = this.burnModel.recipients.sort((a, b) => a.name.localeCompare(b.name));
      // this.recipientModel = { name: null, email: null };

      console.debug('Exit onClickRecipientAdd()');
    },
    onClickRecipientDelete(recipient) {
      this.burnModel.recipients = this.burnModel.recipients.filter((e) => e.email !== recipient.email);
    },
    onSubmitConditions(values) {
      console.debug('Enter BurnArea.onSubmitConditions()');

      console.debug('this.preferredConditionsModel: ', this.preferredConditionsModel);
      console.debug('values: ', values);

      const windDirection = this.preferredConditionsModel.find((e) => e.name === 'windDirection');
      windDirection.value = Object.entries(windDirection.value).map((e) => e[1]);

      const transportWindDirection = this.preferredConditionsModel.find((e) => e.name === 'transportWindDirection');
      transportWindDirection.value = Object.entries(transportWindDirection.value).map((e) => e[1]);

      const temperature = { name: 'temperature', value: { min: values.temperatureMin, max: values.temperatureMax } };
      const relativeHumidity = { name: 'relativeHumidity', value: { min: values.relativeHumidityMin, max: values.relativeHumidityMax } };
      const windSpeed = { name: 'windSpeed', value: { min: values.windSpeedMin, max: values.windSpeedMax } };
      const windGust = { name: 'windGust', value: { min: values.windGustMin, max: values.windGustMax } };
      const quantitativePrecipitation = { name: 'quantitativePrecipitation', value: { min: values.quantitativePrecipitationMin, max: values.quantitativePrecipitationMax } };
      const transportWindSpeed = { name: 'transportWindSpeed', value: { min: values.transportWindSpeedMin, max: values.transportWindSpeedMax } };
      const dispersionIndex = { name: 'dispersionIndex', value: { min: values.dispersionIndexMin, max: values.dispersionIndexMax } };
      const mixingHeight = { name: 'mixingHeight', value: { min: values.mixingHeightMin, max: values.mixingHeightMax } };

      this.burnModel.preferredConditions = [
        temperature,
        relativeHumidity,
        windSpeed,
        { name: windDirection.name, value: windDirection.value },
        windGust,
        quantitativePrecipitation,
        transportWindSpeed,
        { name: transportWindDirection.name, value: transportWindDirection.value },
        dispersionIndex,
        mixingHeight,
      ];
      console.debug('this.burnModel.preferredConditions: ', this.burnModel.preferredConditions);

      this.editConditionsModal.hide();
      this.onClickBurnSave();

      console.debug('Exit BurnArea.onSubmitConditions()');
    },
  },
};
</script>



