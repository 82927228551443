<style lang='scss' scoped>
  input.form-control {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: #555;
  }
  .btn-signup {
    background-color: rgb(68, 68, 28);
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: bold;
    height: 40px;
    width: 100%;
  }
  .btn-signup:hover {
    background-color:  #286090;
  }
  .container-signup{
    background-color: #909090;
    font-size: 14px;
    height: 100%;
  }
  .description-signup {
    color: rgb(68, 68, 28);
    font-size: 16px;
    font-weight:bold;
  }
  .image-signup {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .input-signup {
    color: #555;
  }
  .label-signup {
    color: rgb(68, 68, 28);
    font-family: Arial;
    margin-bottom: 5px;
  }
  .modal-content {
    height: auto;
    width: 350px;
    margin: 0 auto;
  }
  .modal-header-success {
    background-color: rgb(68, 68, 28) !important;
    color: white;
  }
  .success-header, .error-header {
    text-align: center;
  }
  .success-header-text {
    color: rgb(68, 68, 28);
  }
  .error-header-text {
    color: rgb(127, 63, 20);
  }
  label {
    font-family: Arial;
    font-weight: 600;
  }
</style>
<template>
  <div class='container-signup pt-5'>
    <div v-if='showSignUpModal' class='modal-dialog'>
      <div class='modal-content shadow'>
        <div style='text-align:center'>
            <img class='image-signup' alt='logo' src='../assets/sgsf.png'>
        </div>
        <div class='modal-body align-items-center'>
          <span class='description-signup'>Register for a New Account</span>
          <form v-on:submit.prevent autocomplete="off">
            <label class='mt-2' for='email'>Email</label>
            <div>
              <input v-model='email' name='email' type='email' placeholder='name@host.com' autocapitalize="none" class='form-control input-signup' required autocomplete="off">
            </div>
            <label class='mt-2' for='firstName'>First Name</label>
            <div>
              <input v-model='firstName' name='firstName' type='text' class='form-control input-signup' placeholder='First Name' autocapitalize="none" required autocomplete="off">
            </div>
            <label class='mt-2' for='lastName'>Last Name</label>
            <div>
              <input v-model='lastName' name='lastName' type='text' class='form-control input-signup' placeholder='Last Name' autocapitalize="none" required autocomplete="off">
            </div>
            <div v-if='states'>
              <label class='mt-2' for='lastName'>State</label>
              <div>
                <select v-model='state' class="form-select" aria-label="Select State" required autocomplete="off">
                  <option selected disabled value="">Select State</option>
                  <option v-for='state in states' v-bind:value="state.state" :key="state.state">{{state.name}}</option>
                </select>
                <br/>
              </div>
            </div>
            <input class='btn btn-primary btn-signup mt-3' name='signUpSubmitButton' type='Submit' value='Sign Up' @click='onSubmitButtonClick' :disabled='isSubmitDisabled'>
          </form>
        </div>
      </div>
    </div>
    <div v-if='showSuccessModal' class='modal-dialog'>
      <div class='modal-content shadow'>
        <div style="text-align:center">
          <img class='image-signup' alt='logo' src='../assets/sgsf.png'>
        </div>
        <div class='modal-body'>
          <div class='m-0 p-0 success-header'>
            <p>
              <span class='description-signup fs-2 success-header-text'>Request Received!</span>
            </p>
          </div>
          <p>
            <span>You should receive a confirmation email containing the instructions to confirm your registration.</span>
          </p>
          <p>
            <span>Please follow those instructions to complete your account setup.</span>
          </p>
          <p>
            <span>Your confirmation email should be delivered almost immediately.</span>
            <span class='ms-2 fw-bold'>If you do not see it in your inbox, please check your junk email or spam folder.</span>
          </p>
          <p>
              <router-link :to="{ name: 'Home' }">Back to Home</router-link>
          </p>
        </div>
      </div>
    </div>
    <div v-if='showErrorModal' class='modal-dialog'>
      <div class='modal-content shadow'>
        <div style="text-align:center">
          <img class='image-signup' alt='logo' src='../assets/sgsf.png'>
        </div>
        <div class='modal-body'>
          <div class='m-0 p-0 error-header'>
            <p>
              <span class='description-signup fs-2 error-header-text'>ERROR!</span>
            </p>
          </div>
          <p>
            <span>Registration failed with the following messages:</span>
          </p>
          <ul>
            <li v-for='message in userRegistrationModel.messages' :key='message'>{{message}}</li>
          </ul>
          <p>
              <router-link :to="{ name: 'Home' }">Back to Home</router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'SignUp',
  computed: {
    ...mapState('App', {
      states: (state) => state.states,
    }),
    ...mapState('User', {
      serverError: (state) => state.serverError,
      userRegistrationModel: (state) => state.userRegistrationModel,
    }),
    isSubmitDisabled() {
      // https://www.w3resource.com/javascript/form/email-validation.php
      if (!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.email))) return true;
      if (this.email == null || this.email.length === 0) return true;
      if (this.firstName == null || this.firstName.length === 0) return true;
      if (this.lastName == null || this.lastName.length === 0) return true;
      if (this.state == null || this.state.length === 0) return true;
      return false;
    },
  },
  data() {
    return {
      email: null,
      firstName: null,
      lastName: null,
      state: '',
      showSignUpModal: true,
      showSuccessModal: false,
      showErrorModal: false,
    };
  },
  mounted() {
    // console.debug('Enter mounted()');
    this.$store.dispatch('App/fetchStates').then(() => {
      console.debug('this.states: ', this.states);
    });
    // console.debug('Exit mounted()');
  },
  methods: {
    onSubmitButtonClick() {
      const model = {
        email: this.email,
        first: this.firstName,
        last: this.lastName,
        state: this.state,
      };
      this.$store.dispatch('User/postUserRegistrationModel', model).then(() => {
        this.showSignUpModal = false;
        if (this.userRegistrationModel.valid) {
          this.showSuccessModal = true;
        } else {
          this.showErrorModal = true;
        }
      }).catch((exception) => {
        console.error('Enter \'User/postUserRegistrationModel\' catch()');
        console.error('exception: ', exception);
        console.error('Exit \'User/postUserRegistrationModel\' catch()');
      });
    },
  },
};
</script>
