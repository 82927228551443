<style scoped lang="scss">
  .active-burns-router-link-plain {
    color: rgb(114,114,114);
    text-decoration: none;
  }
  .active-burns-router-link-plain:hover {
    text-decoration: underline;
  }
 .form-label {
    font-weight:bold;
    text-align:right;
  }
  .form-label, .form-select, .form-text {
    font-size: 0.75em;
  }
  .form-select {
    width: 80px;
  }
  .pagination-col {
    height: 32px !important;
  }
  .text-nav-small {
    font-weight:bold;
  }
  .card-burn:hover {
    background-color: rgb(235, 235, 235) !important;
  }
  #burn-condition-items {
    text-align: right !important;
  }
  .burn-condition-item {
    display: inline-block;
    height:2em;
    width:2em;
  }
  .burn-condition-item:hover {
    border-color: #6c757d  !important;
    border-style: solid !important;
    border-width: 2px !important;
  }
  .burn-condition-score-low {
    background-color: rgb(184, 49, 49);
  }
  .burn-condition-score-medium {
    background-color: rgb(255, 234, 112);
  }
  .burn-condition-score-high {
    background-color: rgb(128, 145, 59);
  }
  .burn-map-router-link {
    text-transform: uppercase;
  }
  .fire-primary {
    color: red;
  }
  .fire-secondary {
    color: rgb(191, 191, 191);
  }
</style>

<template>
  <div v-if='!pageItems || pageItems.length === 0'>
    <div class='container-fluid'>
      <div class='card shadow mt-2 card-burn'>
        <div class='card-body'>
          <span>Navigate to </span>
          <router-link to="/burnMap" class='active-burns-router-link-plain fw-bold'>Burn Map</router-link>
          <span> tab to begin planning burns.</span>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class='row ms-3 mt-2 me-3'>
      <div class='col'>
        <div class='row'>
          <div class="col d-flex align-items-end pagination-col ps-0 ms-0">
            <label for="pageSizeSelect" class="bold form-label me-3">Per Page</label>
            <select id='pageSizeSelect' class="form-select" v-model='pageSize' aria-label="Select Page Size" @change='setPageSize()'>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
        </div>
      </div>
      <div class='col'>
        <div class='row'>
          <div class="col d-flex align-items-center justify-content-end pagination-col pe-0 me-0">
            <v-pagination
              v-model="page"
              :pages="pages"
              :range-size="1"
              active-color="#d8d8d8 "
              @update:modelValue="onUpdatePageSize"
            />
          </div>
        </div>
      </div>
    </div>
    <div class='container-fluid'>
      <div v-if="!filtering">
        <div class='card shadow mt-2 card-burn' v-for='item in pageItems' :key='item.id'>
          <div class='card-body'>
            <div class='row'>
              <div class='col d-flex align-items-start text-secondary'>
                <router-link class='active-burns-router-link-plain' :to='{ name: "BurnArea", params: { id: item.id }}'>{{ item.name }}</router-link>
              </div>
              <div id='burn-condition-items' class='col-6 pe-0 me-0'>
                <div v-if='type !== "pastBurns"' class='row'>
                  <div class='col d-flex justify-content-end'>
                    <div
                      v-for='(burnScore, index) in item.burnScores'
                      :key='burnScore.startDate'
                      class='border burn-condition-item'
                      v-bind:class='getBurnConditionClass(burnScore)'
                      v-bind:title="getTooltipTitle(burnScore, index)"
                      data-bs-html="true"
                      data-bs-placement="top"
                      data-bs-toggle="tooltip"
                    />
                  </div>
                  <div class='col-1 d-flex align-items-center justify-content-end ps-4 pe-4'>
                    <font-awesome-icon v-if="isBurnAreaReadyToBurn(item)" icon="fire-alt" class='fa-2x fire-primary'/>
                    <font-awesome-icon v-else icon="fire-alt" class='fa-2x fire-secondary'/>
                  </div>
                </div>
              </div>
            </div>
            <div class='card-text'>
              <div class='row'>
                <div class='col'>
                  <span class='form-label'>
                    <router-link class='active-burns-router-link-plain' :to='{ name: "BurnMap", query: { burnId: item.id } }'>View on Map</router-link>
                    <span class='ps-1 pe-1'>|</span>
                    <router-link class='active-burns-router-link-plain' :to='{ name: "BurnArea", params: { id: item.id } }'>Manage Unit</router-link>
                  </span>
                </div>
                <div class='col'><span class='form-label'>Locality:</span><span class='form-text ms-1'>{{item.fields.locality}}</span></div>
                <div class='col'><span class='form-label'>Priority:</span><span class='form-text ms-1'>{{item.fields.priority}}</span></div>
                <div class='col'><span class='form-label'>Planned Acres:</span><span class='form-text ms-1'>{{item.fields.plannedAcres}}</span></div>
                <div class='col'><span class='form-label'>Date Last Burned:</span><span class='form-text ms-1'>{{ item.fields.burnDate ? new Date(item.fields.burnDate).toLocaleDateString() : '' }}</span></div>
                <div v-if='type !== "pastBurns"' class='col'><span class='form-label'>Burn Status:</span><span class='form-text ms-1'>{{item.fields.burnStatus}}</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class='row ms-3 mt-2 me-3 pb-2'>
      <div class='col'>
        <div class='row'>
          <div class="col d-flex align-items-end pagination-col ps-0 ms-0">
            <label for="pageSizeSelect" class="bold form-label me-3">Per Page</label>
            <select id='pageSizeSelect' class="form-select" v-model='pageSize' aria-label="Select Page Size" @change='setPageSize()'>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
        </div>
      </div>
      <div class='col'>
        <div class='row'>
          <div class="col d-flex align-items-center justify-content-end pagination-col pe-0 me-0">
            <v-pagination
              v-model="page"
              :pages="pages"
              :range-size="1"
              active-color="#d8d8d8 "
              @update:modelValue="onUpdatePageSize"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable arrow-body-style */
import { mapState } from 'vuex';
import { Tooltip } from 'bootstrap';
import BurnRepeaterHelper from '@/mixins/burnRepeaterHelper';
import BurnScoreHelper from '@/mixins/burnScoreHelper';
import DateHelper from '@/mixins/dateHelper';
import DirectionHelper from '@/mixins/directionHelper';
import PreferredConditionHelper from '@/mixins/preferredConditionHelper';

export default {
  name: 'BurnRepeater',
  mixins: [
    BurnRepeaterHelper,
    BurnScoreHelper,
    DateHelper,
    DirectionHelper,
    PreferredConditionHelper,
  ],
  props: {
    msg: String,
    type: String,
  },
  computed: {
    ...mapState(['userName', 'userEmail']),
    ...mapState('BurnArea', {
      myBurns: (state) => state.myBurns,
      myStats: (state) => state.myStats,
      searchString: (state) => state.searchString,
    }),
  },
  data() {
    return {
      filteredItems: [],
      filtering: false,
      myBurnScores: [],
      page: 1,
      pages: null,
      pageSize: 5,
      pageItems: [],
      tooltipTriggerList: null,
      tooltipList: null,
    };
  },
  watch: {
    /* eslint-disable-next-line */
    myBurns: function() {},
    /* eslint-disable-next-line */
    type: function() {
      this.getBurns(this.type);
    },
    /* eslint-disable-next-line */
    searchString: function() {
      this.setFilteredItems();
    },
  },
  mounted() {
    this.getBurns(this.type);
  },
  updated() {
    this.setTooltips();
  },
  methods: {
    getBurns(type) {
      console.debug('Enter getBurns()');

      this.myBurnScores = [];

      this.$store.dispatch('BurnArea/getStats').then(() => {
        switch (type) {
          case 'activeBurns':
            this.$store.dispatch('BurnArea/getActiveBurns', this.userName).then(() => {
              this.setBurnConditions();
              this.filteredItems = this.myBurns;
              this.setFilteredItems();
              this.setPages();
            });
            break;
          case 'readyToBurns':
            this.$store.dispatch('BurnArea/getActiveBurns', this.userName).then(() => {
              this.setBurnConditions();
              const myBurns = this.myBurns.filter((myBurn) => {
                const readyToBurnScores = myBurn.burnScores.filter((burnScore) => this.isScoreReadyToBurn(burnScore.score));
                console.debug('readyToBurnScores: ', readyToBurnScores);
                return readyToBurnScores.length > 0;
              });
              console.debug('myBurns: ', myBurns);
              this.$store.commit('BurnArea/setMyBurns', myBurns);
              this.filteredItems = this.myBurns;
              this.setFilteredItems();
              this.setPages();
            });
            break;
          case 'pastBurns':
            this.$store.dispatch('BurnArea/getInactiveBurns', this.userName).then(() => {
              this.filteredItems = this.myBurns;
              this.setFilteredItems();
              this.setPages();
            });
            break;
          default:
            break;
        }
      });

      console.debug('Exit getBurns()');
    },
    getDateLastBurned(item) {
      if (!item.fields.burnDate || item.fields.burnDate == null) {
        return '';
      }
      return new Date(item.fields.burnDate).toLocaleDateString();
    },
    getFirePrimaryStyle(item) {
      const burnConditionCount = item.burnConditions.length - item.burnConditions.filter((e) => e.burnCondition && e.burnCondition >= 8).length;
      return {
        animationDuration: `${burnConditionCount / 2}s`,
      };
    },
    getTooltipTitle(item) {
      // console.debug('Enter getTooltipTitle()');

      // console.debug('item: ', item);
      // console.debug('index: ', index);

      let startDate = item.startDate;
      // console.debug('startDate: ', startDate);

      const timezoneOffsetMilliseconds = startDate.getTimezoneOffset() * 60 * 1000;
      // console.debug('timezoneOffsetMilliseconds: ', timezoneOffsetMilliseconds);

      startDate = new Date(startDate.getTime() + timezoneOffsetMilliseconds);
      // console.debug('startDate: ', startDate);

      // https://stackoverflow.com/questions/65547199/using-bootstrap-5-with-vue-3
      if (!item.score) {
        console.warn('Required burnScore property \'score\' not set!');
        return null;
      }

      const score = item.score;
      const conditionsMet = score.requiredPreferredConditionsMet + score.optionalPreferredConditionsMet;
      const conditionsSet = score.requiredPreferredConditionsSet + score.optionalPreferredConditionsSet;
      const percentageMet = (conditionsMet / conditionsSet);

      let tooltipTitle = null;

      if (!Number.isNaN(percentageMet)) {
        tooltipTitle = `${startDate.toLocaleDateString()}<br/>Percentage Met: ${(percentageMet * 100).toFixed(2)}%`;
      } else {
        tooltipTitle = `${startDate.toLocaleDateString()}<br/>Percentage Met: N/A`;
      }
      // console.debug('tooltipTitle: ', tooltipTitle);

      // console.debug('Exit getTooltipTitle()');
      return tooltipTitle;
    },
    onUpdatePageSize() {
      this.setPages();
    },
    setBurnConditions() {
      console.debug('Enter setBurnConditions()');

      this.myBurns.forEach((e) => {

        const myBurnStat = this.myStats.find(
          (stat) => Number(stat.fields.burnAreaId) === Number(e.id),
        );

        // e.fields.burnConditions = [...this.burnConditionDates];
        e.fields.burnConditions = this.getWeatherConditionDates(myBurnStat);

        if (myBurnStat && myBurnStat.fields && myBurnStat.fields.weatherConditions) {

          const weatherConditions = myBurnStat.fields.weatherConditions.map((weatherCondition) => weatherCondition.values).flat();

          e.fields.burnConditions.forEach((burnCondition) => {

            const burnConditionStartDate = burnCondition.startDate;
            // console.debug('burnConditionStartDate: ', burnConditionStartDate);

            // eslint-disable-next-line
            burnCondition.weatherConditions = weatherConditions.filter((weatherCondition) => {
              // console.debug('weatherCondition: ', weatherCondition);
              const weatherConditionStartDate = weatherCondition.startDate;
              // weatherConditionStartDate = this.getGMTDate(new Date(weatherConditionStartDate));
              // console.debug('weatherConditionStartDate: ', weatherConditionStartDate);
              return new Date(weatherConditionStartDate).valueOf() === burnConditionStartDate.valueOf();
            });
            // console.debug('burnCondition.weatherConditions: ', burnCondition.weatherConditions);

            const burnConditionScore = this.getBurnConditionScore(burnCondition, e.fields.preferredConditions);
            // console.debug('burnConditionScore: ', burnConditionScore);

            this.myBurnScores.push({
              burnAreaId: e.id,
              startDate: burnCondition.startDate,
              score: burnConditionScore,
            });

            // console.debug('');
          });
        }
      });

      // console.debug('this.myBurnScores: ', this.myBurnScores);

      this.myBurns.forEach((e, i) => {
        this.myBurns[i].burnScores = this.myBurnScores.filter((burnScore) => burnScore.burnAreaId === e.id);
      });

      // console.debug('this.myBurns: ', this.myBurns);

      console.debug('Exit setBurnConditions()');
    },
    setFilteredItems() {
      this.filtering = true;

      if (this.searchString && this.searchString !== null && this.searchString !== '') {
        const searchTerm = this.searchString.toUpperCase();
        this.filteredItems = this.myBurns.filter((e) => {
          if (e.name.toUpperCase().includes(searchTerm)) { return true; }
          if (e.fields.locality.toUpperCase().includes(searchTerm)) { return true; }
          if (e.fields.priority.toUpperCase().includes(searchTerm)) { return true; }
          return false;
        });
      } else {
        this.filteredItems = this.myBurns.map((e) => e);
      }
      this.setPages();

      this.filtering = false;
    },
    setPageSize() {
      this.$store.commit('BurnArea/setPageSize', this.pageSize);
      this.page = 1;
      this.setPages();
    },
    setPages() {
      let pages = Math.floor(this.filteredItems.length / this.pageSize);
      if ((this.filteredItems.length % this.pageSize) > 0) {
        pages += 1;
      }
      this.pages = pages;
      this.setPageItems();
      this.setTooltips();
    },
    setPageItems() {
      const startIndex = Number(this.page - 1) * Number(this.pageSize);
      const endIndex = Number(startIndex) + Number(this.pageSize);
      this.pageItems = this.filteredItems.slice(startIndex, endIndex);
    },
    setTooltips() {
      this.tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
      this.tooltipList = this.tooltipTriggerList.map((e) => {
        return new Tooltip(e);
      });
    },
  },
};
</script>
