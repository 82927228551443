<style lang="scss">
  #app {
    height:100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .is-burnlist-route {
    text-decoration: underline !important;
  }
  .is-signed-in {
    height: calc(100% - 74px) !important;
  }
  // .is-signed-in-home {
  //   // height: calc(100% - 26px) !important;
  //   height: 0px;
  // }
  .is-signed-out {
    height: calc(100% - 53px) !important;
  }
  #logo-sgsf {
    height:0;
    position:relative;
    top:-46px;
  }
  #img-sgsf {
    filter: drop-shadow(5px 5px 5px black);
    height: 70px;
  }
  #navbar-container-top {
    padding-top:0;
    padding-bottom:0;
  }
  #routerViewContainer {
    overflow:auto;
  }
  .navbar-padding-none{
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .navbar-padding-small{
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }
  .router-link-plain {
    text-decoration: none !important;
  }
  .router-link-exact-active {
    text-decoration: underline !important;
  }
  .router-link-underline {
    text-decoration: underline !important;
  }
  .text-copyright {
    font-size: .75em;
    padding-left: 1em
  }
  .text-nav-small {
    font-size: .75em;
  }
  .text-nav-upper{
    text-transform: uppercase;
  }
</style>
<!-- https://www.codeply.com/p/P0KN7DNsEq -->
<template>
  <div
    v-if='showNavbarContainer'
    style='height: 100%;'
  >
    <!-- <div v-bind:class="{ 'is-signed-in': isSignedIn && routeName !=='Home', 'is-signed-in-home': isSignedIn && routeName === 'Home', 'is-signed-out': !isSignedIn}"></div> -->
    <div id='navBarContainer' class='border border-dark shadow p-0 m-0'>
      <nav v-if="!isSignedIn" id='navBarSignedOut' class="bg-primary navbar navbar-expand-md">
        <div class="container-fluid">
          <span class='text-light text-copyright'>&copy; 2022 Southern Group of State Foresters</span>
          <button type='button' class="btn btn-primary btn-rounded router-link-plain" to="/burnareas/myactiveburns" @click='onClickSignIn'>Log In</button>
        </div>
      </nav>
      <div v-else>
        <nav class="navbar navbar-light navbar-expand-md justify-content-end bg-dark navbar-padding-none">
          <div class='container-fluid '>
            <div id='navbar-container-top' class="navbar-text d-flex w-100 me-auto text-light justify-content-end">
              <ul class="navbar-nav w-100 justify-content-end">
                <li class="nav-item">
                  <span class='me-3 text-light text-nav-small'>Welcome, {{ userFirstName ? userFirstName : userEmail }}!</span>
                </li>
                <li class="nav-item">
                  <router-link class='me-3 router-link-plain text-light text-nav-small router-link-underline' to="/" @click='onClickSignOut'>Log Out</router-link>
                </li>
                <li>
                  <span class="version text-nav-small">Version {{version}}.{{build}}</span>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <!-- <nav v-if='routeName !== "Home"' class="navbar navbar-light navbar-expand-md justify-content-end bg-primary navbar-padding-small"> -->
        <nav class="navbar navbar-light navbar-expand-md justify-content-end bg-primary navbar-padding-small">
          <div class="container-fluid">
            <div id='logo-sgsf' class='ms-3 me-3'>
              <img id='img-sgsf' src="./assets/sgsf.svg">
            </div>
            <router-link class='navbar-brand d-flex w-50 me-auto text-light justify-content-start' to="/">Prescribed Burn Planner</router-link>
            <div class="navbar-collapse collapse" id="collapsingNavbar3">
              <ul class="navbar-nav w-100 justify-content-end">
                <li class="me-5 nav-item" :class="{'is-burnlist-route': isBurnListRoute }">
                  <router-link to="/burnareas/myactiveburns" class='router-link-plain text-light text-nav-small text-nav-upper'>Burn List</router-link>
                </li>
                <li class="me-3 nav-item">
                  <router-link to="/burnMap" class='router-link-plain text-light text-nav-small text-nav-upper'>Burn Map</router-link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
    <!-- <div id='routerViewContainer' v-bind:class="{ 'is-signed-in': isSignedIn && routeName !=='Home', 'is-signed-in-home': isSignedIn && routeName === 'Home', 'is-signed-out': !isSignedIn}"> -->
    <div id='routerViewContainer' v-bind:class="{ 'is-signed-in': isSignedIn, 'is-signed-out': !isSignedIn}">
      <router-view />
    </div>
  </div>
  <div id='routerViewContainer' v-else style='height: 100%;'>
    <router-view />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { CognitoAuth } from 'amazon-cognito-auth-js';

export default {
  name: 'App',
  components: {},
  data() {
    return {
      isBurnListRoute: false,
    };
  },
  computed: {
    ...mapState(['userName', 'userEmail', 'userFirstName', 'userLastName']),
    ...mapState('App', {
      auth: (state) => state.auth,
      version: (state) => state.version,
      build: (state) => state.build,
      showNavbarContainer: (state) => state.showNavbarContainer,
    }),
    ...mapState('Navigation', {
      to: (state) => state.to,
      from: (state) => state.from,
      current: (state) => state.current,
    }),
    isSignedIn() {
      let isSignedIn = false;
      if (this.auth !== null && this.auth.isUserSignedIn()) {
        isSignedIn = true;
      }
      return isSignedIn;
    },
    routeName() {
      let routeName = '';
      if (this.to && this.to != null) {
        routeName = this.to.name;
      }
      return routeName;
    },
  },
  watch: {
    // eslint-disable-next-line
    current: function() {
      if (['MYACTIVEBURNS', 'MYPASTBURNS', 'MYREADYTOBURNS'].includes(this.current.name.toUpperCase())) {
        this.isBurnListRoute = true;
      } else {
        this.isBurnListRoute = false;
      }
    },
  },
  mounted() {

    // https://www.npmjs.com/package/amazon-cognito-identity-js

    const authConfig = this.$store.getters['App/getAuthConfig'];

    const auth = new CognitoAuth({
      ClientId: authConfig.ClientId,
      UserPoolId: authConfig.UserPoolId,
      AppWebDomain: authConfig.AppWebDomain,
      TokenScopesArray: authConfig.TokenScopesArray,
      RedirectUriSignIn: `${window.location.protocol}//${window.location.host}/${authConfig.RedirectUriSignIn}`,
      RedirectUriSignOut: `${window.location.protocol}//${window.location.host}/${authConfig.RedirectUriSignOut}`,
    });
    this.$store.commit('App/setAuth', auth);

    if (this.auth.isUserSignedIn()) {
      this.$store.commit('setUserName', this.auth.username);
      const signInUserSession = this.auth.getSignInUserSession();
      const idToken = signInUserSession.getIdToken();
      // localStorage.setItem('pbp:jwtToken', idToken.jwtToken);
      const payload = idToken.decodePayload();
      this.$store.commit('setUserEmail', payload.email);
      if (payload['custom:firstName']) {
        this.$store.commit('setUserFirstName', payload['custom:firstName']);
      }
      if (payload['custom:lastName']) {
        this.$store.commit('setUserLastName', payload['custom:lastName']);
      }
    }

    this.$store.dispatch('App/fetchGeocoderConfig');
    this.$store.dispatch('App/fetchStates');
  },
  methods: {
    onClickSignIn() {
      this.$store.commit('Navigation/setTo', { name: 'MyActiveBurns' });
      this.auth.getSession();
    },
    onClickSignOut() {
      this.auth.signOut();
    },
  },
};
</script>
