<template>
  <div class='container-fluid mt-3'>You have been signed out of this site.</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'SignOut',
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapState('App', {
      auth: (state) => state.auth,
    }),
  },
  mounted() {
    this.$router.push({ name: 'Home' });
  },
};
</script>

