import axios from 'axios';
import { createApp } from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBan,
  faBars,
  faChevronLeft,
  faChevronRight,
  faFire,
  faFireAlt,
  faHome,
  faLayerGroup,
  faMap,
  faPen,
  faSearch,
  faSeedling,
  faSpinner,
  faTable,
  faUserSecret,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VPagination from '@hennge/vue3-pagination';
import '@hennge/vue3-pagination/dist/vue3-pagination.css';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../sass/base.scss';

(async () => {

  const getAuthConfigRequest = axios.create({ baseURL: '/api' });
  await getAuthConfigRequest.get('/app/getauthconfig/').then(async (response) => {
    localStorage.setItem('pbp:authConfig', JSON.stringify(response.data));
  });

  // https://www.npmjs.com/package/@fortawesome/vue-fontawesome
  library.add(
    faBan,
    faBars,
    faChevronLeft,
    faChevronRight,
    faFire,
    faFireAlt,
    faHome,
    faLayerGroup,
    faMap,
    faPen,
    faSearch,
    faSeedling,
    faSpinner,
    faTable,
    faUserSecret,
  );

  const app = createApp(App);

  app.use(store).use(router);
  app.config.productionTip = false;
  app.component('font-awesome-icon', FontAwesomeIcon);
  app.component('v-pagination', VPagination);

  app.mount('#app');

})();

