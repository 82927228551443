/* eslint no-shadow: ["error", { "allow": ["state"] }] */

export const namespaced = true;

export const state = {
  to: null,
  from: null,
  current: null,
};

export const mutations = {
  setTo(state, value) {
    localStorage.setItem('pbp:navigation:to', JSON.stringify(value));
    state.to = value;
  },
  setFrom(state, value) {
    localStorage.setItem('pbp:navigation:from', JSON.stringify(value));
    state.from = value;
  },
  setCurrent(state, value) {
    state.current = value;
  },
};

export const getters = {
  getTo: () => {
    state.to = JSON.parse(localStorage.getItem('pbp:navigation:to'));
    return state.to;
  },
  getFrom: () => {
    state.from = JSON.parse(localStorage.getItem('pbp:navigation:from'));
    return state.from;
  },
};
