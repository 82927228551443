<style lang="scss" scoped>
#burnUnitMap{
  width:100%;
  height: 100%;
}
.btn-active {
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.50) !important;
}
</style>

<template>
  <!-- <font-awesome-icon icon="home" /> -->
  <!-- <svg xmlns="http://www.w3.org/2000/svg" class="zoom-to-extent-img" width="576" height="480" viewBox="0 0 576 480"><path class="" fill="white" d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z"></path></svg> -->
  <div id='burnUnitMap' ref='burnUnitMap' class='m-0 p-0'/>
  <div style='display:none;'>
    <div class='ol-unselectable ol-layer-toggle m-0 p-0 ms-2 mt-2' id='layerToggleControl' ref='layerToggleControl'>
      <button type='button' class='btn btn-primary btn-rounded-small btn-rounded-left shadow' :class="{ 'btn-active': activeLayer === 'lyr_esri_world_image'}" @click="onClickToggleLayer('lyr_esri_world_image')">Aerial</button>
      <button  type='button' class='btn btn-primary btn-rounded-small btn-rounded-right shadow' :class="{ 'btn-active': activeLayer === 'lyr_esri_topo'}" @click="onClickToggleLayer('lyr_esri_topo')">Topography</button>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
import { mapState } from 'vuex';
import 'ol/ol.css';
import { ZoomToExtent, defaults as defaultControls } from 'ol/control';
import { WKT } from 'ol/format';
import { Group as GroupLayer, Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import Map from 'ol/Map';
import { TileArcGISRest, Vector as VectorSource } from 'ol/source';
import { Circle as CircleStyle, Icon as IconStyle, Fill, Stroke, Style } from 'ol/style';
import View from 'ol/View';
import LayerToggleControl from '@/mixins/layerToggleControl';

export default {
  name: 'BurnAreaMap',
  props: {
    id: Number,
    readyToBurn: Boolean,
  },
  emits: [],
  components: {},
  mixins: [LayerToggleControl],
  computed: {
    ...mapState('App', {
      burnUnitMapConfig: (state) => state.burnUnitMapConfig,
    }),
    ...mapState('BurnArea', {
      myBurn: (state) => state.myBurn,
      myBurnStats: (state) => state.myBurnStats,
    }),
  },
  data() {
    return {
      activeLayer: 'lyr_esri_world_image',
      target: 'burnUnitMap',
      burnStyleActive: null,
      burnStyleActiveReadyToBurn: null,
      burnStyleInactive: null,
      burnStyleUnspecified: null,
      burnUnitMap: null,
      center: null,
      extent: null,
      formLoading: false,
      tileLayers: [],
      view: null,
    };
  },
  watch: {
    /* eslint-disable-next-line */
    myBurn: function() {
      console.debug('Enter watch.myBurn()');

      this.loadMyBurnFeature();

      console.debug('Exit watch.myBurn()');
    },
    /* eslint-disable-next-line */
    readyToBurn: function() {
      console.debug('Enter watch.readyToBurn()');

      this.loadMyBurnFeature();

      console.debug('Exit watch.readyToBurn()');
    },
  },
  mounted() {
    // console.debug(window.btoa('<svg xmlns="http://www.w3.org/2000/svg" class="zoom-to-extent-img" width="576" height="480" viewBox="0 0 576 480"><path class="" fill="white" d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z"></path></svg>'));
    this.load();
  },
  methods: {
    getBurnStyle(feature) {
      // console.debug('Enter getBurnStyle()');

      let burnStyle = this.burnStyleUnspecified;

      const burnStatus = feature.get('burnStatus');

      if (burnStatus === 'Active' && !this.readyToBurn) {
        burnStyle = this.burnStyleActive;
      }
      if (burnStatus === 'Active' && this.readyToBurn) {
        burnStyle = this.burnStyleActiveReadyToBurn;
      }
      if (burnStatus !== 'Active') {
        burnStyle = this.burnStyleInactive;
      }

      // console.debug('Exit getBurnStyle()');
      return burnStyle;
    },
    load() {
      while (this.$refs.burnUnitMap.lastChild) {
        this.$refs.burnUnitMap.removeChild(this.$refs.burnUnitMap.lastChild);
      }
      this.formLoading = true;
      this.$store.dispatch('App/fetchBurnUnitMapConfig').then(() => {
        this.loadBurnUnitMap();
        this.loadBurnUnitGroup();
        this.formLoading = false;
      });
    },
    loadBaseLayers() {

      const baseMapsGroup = this.burnUnitMapConfig.layerGroups.find((e) => e.key === 'grp_basemaps');

      baseMapsGroup.layers.forEach((layer) => {

        if (!Array.isArray(layer)) {

          const baseMapLayer = this.burnUnitMapConfig.layers.find((e) => e.key === layer);

          const url = baseMapLayer.xyz.endpoints[0].url;
          const source = new TileArcGISRest({ url });

          const tileLayer = new TileLayer({
            key: baseMapLayer.key,
            title: baseMapLayer.name,
            type: 'base',
            visible: false,
            source,
          });

          this.tileLayers.push(tileLayer);

        } else {

          const baseMapLayers = [];
          const subLayers = [];

          layer.forEach((subLayer) => {

            const baseMapLayer = this.burnUnitMapConfig.layers.find((e) => e.key === subLayer);

            const url = baseMapLayer.xyz.endpoints[0].url;
            const source = new TileArcGISRest({ url });

            const subTileLayer = new TileLayer({
              source,
            });

            baseMapLayers.push(baseMapLayer);

            subLayers.push(subTileLayer);
          });

          const baseMapLayerGroup = new GroupLayer({
            key: baseMapLayers[0].key,
            title: baseMapLayers[0].name,
            type: 'base',
            combine: true,
            visible: false,
            layers: subLayers,
          });

          this.tileLayers.push(baseMapLayerGroup);
        }

      });

      this.tileLayers[0].set('visible', true);
      this.tileLayers.reverse();

      return new GroupLayer({
        title: 'Basemaps',
        layers: this.tileLayers,
        visible: true,
      });
    },
    loadBurnUnitGroup() {

      this.loadBurnUnitStyles();

      this.burnUnitLayer = this.loadBurnUnitLayer();

      this.burnUnitGroupLayer = new GroupLayer({
        title: 'My Burn Units Group',
        layers: [this.burnUnitLayer],
        visible: true,
      });

      this.burnUnitMap.addLayer(this.burnUnitGroupLayer);

    },
    loadBurnUnitLayer() {

      const feature = new WKT().readFeature(
        this.myBurn.fields.Geometry.wkt,
        {
          dataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:3857',
        },
      );

      feature.setId(this.myBurn.id);

      feature.setProperties({
        id: this.myBurn.id,
        name: this.myBurn.name,
        burnStatus: this.myBurn.fields.burnStatus,
        burnCondition: this.myBurn.fields.burnCondition,
      });

      const vectorSource = new VectorSource({
        features: [feature],
      });

      return new VectorLayer({
        title: 'My Burn Units Layer',
        visible: true,
        source: vectorSource,
        style: this.getBurnStyle,
      });
    },
    loadBurnUnitStyles() {

      // https://stackoverflow.com/questions/37829941/use-svg-icon-as-marker-in-openlayers

      let svg = '<svg xmlns="http://www.w3.org/2000/svg" height="512" width="512"><path class="" stroke="white" stroke-width="30" fill="rgb(131, 146, 58)" d="M323.56 51.2c-20.8 19.3-39.58 39.59-56.22 59.97C240.08 73.62 206.28 35.53 168 0 69.74 91.17 0 209.96 0 281.6 0 408.85 100.29 512 224 512s224-103.15 224-230.4c0-53.27-51.98-163.14-124.44-230.4z"></path></svg>';
      this.burnStyleActive = new Style({
        image: new IconStyle({
          opacity: 1,
          src: `data:image/svg+xml;base64,${window.btoa(svg)}`,
          scale: 0.05,
        }),
      });

      svg = '<svg xmlns="http://www.w3.org/2000/svg" height="512" width="512"><path class="" fill="red" d="M323.56 51.2c-20.8 19.3-39.58 39.59-56.22 59.97C240.08 73.62 206.28 35.53 168 0 69.74 91.17 0 209.96 0 281.6 0 408.85 100.29 512 224 512s224-103.15 224-230.4c0-53.27-51.98-163.14-124.44-230.4zm-19.47 340.65C282.43 407.01 255.72 416 226.86 416 154.71 416 96 368.26 96 290.75c0-38.61 24.31-72.63 72.79-130.75 6.93 7.98 98.83 125.34 98.83 125.34l58.63-66.88c4.14 6.85 7.91 13.55 11.27 19.97 27.35 52.19 15.81 118.97-33.43 153.42z"></path></svg>';
      this.burnStyleActiveReadyToBurn = new Style({
        image: new IconStyle({
          opacity: 1,
          src: `data:image/svg+xml;base64,${window.btoa(svg)}`,
          scale: 0.05,
        }),
      });

      this.burnStyleInactive = new Style({
        image: new CircleStyle({
          radius: 10,
          stroke: new Stroke({ color: 'black', width: 1 }),
          fill: new Fill({ color: 'rgba(191, 191, 191, 1.00)' }),
        }),
      });

      this.burnStyleUnspecified = new Style({
        image: new CircleStyle({
          radius: 10,
          stroke: new Stroke({ color: 'black', width: 1 }),
          fill: new Fill({ color: 'rgba(191, 191, 191, 0.10)' }),
        }),
      });

    },
    loadBurnUnitMap() {

      const controls = this.loadControls();
      const layers = this.loadBaseLayers();

      this.view = this.loadView();

      this.burnUnitMap = new Map({
        controls,
        layers: [layers],
        target: this.target,
        view: this.view,
      });

      const zoomToExtentControl = new ZoomToExtent({
        label: '',
        extent: this.burnUnitMap.getView().calculateExtent(),
      });

      this.burnUnitMap.addControl(zoomToExtentControl);

    },
    loadControls() {
      const layerToggleControl = new LayerToggleControl({ element: this.$refs.layerToggleControl });
      return defaultControls().extend([layerToggleControl]);
    },
    loadMyBurnFeature() {
      const feature = new WKT().readFeature(
        this.myBurn.fields.Geometry.wkt,
        {
          dataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:3857',
        },
      );
      feature.setId(this.myBurn.id);
      feature.setProperties({
        id: this.myBurn.id,
        name: this.myBurn.name,
        burnStatus: this.myBurn.fields.burnStatus,
        burnCondition: this.myBurn.fields.burnCondition,
      });
      this.burnUnitLayer.getSource().clear();
      this.burnUnitLayer.getSource().addFeature(feature);
    },
    loadView() {
      const feature = new WKT().readFeature(
        this.myBurn.fields.Geometry.wkt,
        {
          dataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:3857',
        },
      );
      this.center = feature.getGeometry().getCoordinates();
      const zoom = this.burnUnitMapConfig.zoom;
      const view = new View({
        center: this.center,
        zoom,
      });
      return view;
    },
    onClickToggleLayer(key) {
      this.activeLayer = key;
      this.tileLayers.filter((e) => e.get('key') !== key).forEach((e) => e.setVisible(false));
      this.tileLayers.filter((e) => e.get('key') === key).forEach((e) => e.setVisible(true));
    },
  },
};
</script>
