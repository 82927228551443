export default {
  methods: {
    isPreferredConditionMet(preferredCondition, weatherCondition) {

      if (!preferredCondition || !weatherCondition) return false;
      if (preferredCondition.value === undefined || preferredCondition.value === null) return false;
      if (weatherCondition.value === undefined || weatherCondition.value === null) return false;

      switch (weatherCondition.valType) {
        case 'cumulative':
          return this.isPreferredConditionMetCumulative(preferredCondition, weatherCondition);
        case 'direction':
          return this.isPreferredConditionMetDirection(preferredCondition, weatherCondition);
        case 'range':
          return this.isPreferredConditionMetRange(preferredCondition, weatherCondition);
        default:
          return weatherCondition.value === preferredCondition.value;
      }

    },
    isPreferredConditionMetCumulative(preferredCondition, weatherCondition) {

      // console.debug('preferredCondition: ', preferredCondition);
      // console.debug('weatherCondition: ', weatherCondition);

      if (preferredCondition.value === null) return false;
      if (preferredCondition.value.min === null || preferredCondition.value.min === '') return false;
      if (preferredCondition.value.max === null || preferredCondition.value.max === '') return false;

      if (weatherCondition.name === 'quantitativePrecipitation') {
        const value = weatherCondition.value * (0.0393701);
        return (preferredCondition.value.min <= value) && (value <= preferredCondition.value.max);
      }
      return (preferredCondition.value.min <= weatherCondition.value) && (weatherCondition.value <= preferredCondition.value.max);
    },
    isPreferredConditionMetDirection(preferredCondition, weatherCondition) {
      const preferredDirections = preferredCondition.value;
      const direction = this.getWeatherConditionDirection(weatherCondition);
      if (preferredDirections.includes(direction)) {
        return true;
      }
      return false;
    },
    isPreferredConditionMetDiscrete(preferredCondition, weatherCondition) {
      console.debug('Enter isPreferredConditionMetDiscrete()');

      if (weatherCondition.name === 'dispersionIndex') return Number(preferredCondition.value) === Math.round(Number(weatherCondition.value));

      console.debug('Exit isPreferredConditionMetDiscrete()');
      return Number(preferredCondition.value) === Number(weatherCondition.value);
    },
    isPreferredConditionMetRange(preferredCondition, weatherCondition) {

      if (weatherCondition.value.min === undefined || weatherCondition.value.min === null) return false;
      if (weatherCondition.value.avg === undefined || weatherCondition.value.avg === null) return false;
      if (weatherCondition.value.max === undefined || weatherCondition.value.max === null) return false;

      if (weatherCondition.name === 'temperature') {
        const max = weatherCondition.value.max * (9 / 5) + 32;
        return (preferredCondition.value.min <= max) && (max <= preferredCondition.value.max);
      }

      if (weatherCondition.name === 'relativeHumidity') {
        const min = weatherCondition.value.min;
        return (preferredCondition.value.min <= min) && (min <= preferredCondition.value.max);
      }

      if (weatherCondition.name === 'windSpeed') {
        const avg = weatherCondition.value.avg * (0.621371);
        return (preferredCondition.value.min <= avg) && (avg <= preferredCondition.value.max);
      }

      if (weatherCondition.name === 'windGust') {
        const avg = weatherCondition.value.avg * (0.621371);
        return (preferredCondition.value.min <= avg) && (avg <= preferredCondition.value.max);
      }

      if (weatherCondition.name === 'transportWindSpeed') {
        const avg = weatherCondition.value.avg * (0.621371);
        return (preferredCondition.value.min <= avg) && (avg <= preferredCondition.value.max);
      }

      if (weatherCondition.name === 'dispersionIndex') {
        const avg = weatherCondition.value.avg;
        return (preferredCondition.value.min <= avg) && (avg <= preferredCondition.value.max);
      }

      if (weatherCondition.name === 'mixingHeight') {
        const avg = weatherCondition.value.avg * 3.28084;
        return (preferredCondition.value.min <= avg) && (avg <= preferredCondition.value.max);
      }

      return (preferredCondition.value.min <= weatherCondition.value.avg) && (weatherCondition.value.avg <= preferredCondition.value.max);
    },
    isPreferredConditionSet(preferredCondition) {

      if (preferredCondition.value === undefined || preferredCondition.value === null || preferredCondition.value === '') return false;

      // console.debug('preferredCondition.name: ', preferredCondition.name);
      // console.debug('preferredCondition.valType: ', preferredCondition.valType);

      let isPreferredConditionSet = false;
      switch (preferredCondition.valType) {
        case 'cumulative':
          isPreferredConditionSet = true;
          break;
        case 'direction':
          if (preferredCondition.value.length > 0) {
            isPreferredConditionSet = true;
          }
          break;
        case 'range':
          if ((preferredCondition.value.min !== null && preferredCondition.value.min !== '') && (preferredCondition.value.max !== null) && preferredCondition.value.max !== null) {
            isPreferredConditionSet = true;
          }
          break;
        default:
          break;
      }
      return isPreferredConditionSet;
    },
  },
};
