/* eslint-disable import/no-cycle, no-new-wrappers, prefer-destructuring */
import { createStore } from 'vuex';
import * as App from './modules/app';
import * as BurnArea from './modules/burnArea';
import * as Navigation from './modules/navigation';
import * as SearchHistory from './modules/searchHistory';
import * as User from './modules/user';

export const namespaced = true;

export default createStore({
  modules: {
    App,
    BurnArea,
    Navigation,
    SearchHistory,
    User,
  },
  state: {
    userEmail: null,
    userFirstName: null,
    userLastName: null,
    userName: null,
  },
  getters: {},
  mutations: {
    setUserFirstName(state, value) {
      state.userFirstName = value;
    },
    setUserLastName(state, value) {
      state.userLastName = value;
    },
    setUserName(state, value) {
      state.userName = value;
    },
    setUserEmail(state, value) {
      state.userEmail = value;
    },
  },
  actions: {},
});
