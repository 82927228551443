<template>
  <div class='container-fluid mt-3'></div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'SignIn',
  computed: {
    ...mapState(['userName', 'userEmail']),
    ...mapState('App', {
      auth: (state) => state.auth,
    }),
  },
  mounted() {
    this.auth.userhandler = {
      onSuccess: () => {
        this.$store.commit('setUserName', this.auth.username);
        const signInUserSession = this.auth.getSignInUserSession();
        const idToken = signInUserSession.getIdToken();
        // localStorage.setItem('pbp:jwtToken', idToken.jwtToken);
        const payload = idToken.decodePayload();
        console.debug('payload: ', payload);
        this.$store.commit('setUserEmail', payload.email);
        if (payload['custom:firstName']) {
          this.$store.commit('setUserFirstName', payload['custom:firstName']);
        }
        if (payload['custom:lastName']) {
          this.$store.commit('setUserLastName', payload['custom:lastName']);
        }
      },
      onFailure: (error) => {
        console.error('Enter auth.onFailure()');
        console.error('error: ', error);
        console.error('Exit auth.onFailure()');
      },
    };
    this.auth.parseCognitoWebResponse(window.location.href);
    const to = this.$store.getters['Navigation/getTo'];
    if (to !== null) {
      this.$router.push({ name: to.name, params: to.params, query: to.query });
    } else {
      this.$router.push({ name: 'MyActiveBurns' });
    }
  },
};
</script>
