<style lang='scss' scoped>
  .modal-header-error {
    background-color: #7f3f14 !important;
    color: white;
  }
  .modal-header-success {
    background-color: rgb(68, 68, 28) !important;
    color: white;
  }
  .modal-header-warning {
    background-color: #7f3f14 !important;
    color: white;
  }
  .success-header, .error-header, .warning-header {
    text-align: center;
  }
</style>
<template>
  <div id='subscribeErrorModal' class="modal fade" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header modal-header-error p-2 ps-3 pe-3">
          <h5 class="modal-title">Subscribe Failed!</h5>
          <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class='form-label text-danger fs-5 fw-bold'>Your subscription could not be added!</div>
          <div v-if='serverError && serverError.message' class='pt-2 server-error'>
            <span class='form-label fw-bold pe-2'>Message:</span>
            <span class='form-text'>{{serverError.message}}</span>
          </div>
          <div v-if='serverError && serverError.config'>
            <div v-if='serverError.config.method && serverError.config.url' class='pt-2 server-error'>
              <span class='form-label fw-bold pe-2'>Request:</span>
              <span class='form-text pe-1'>{{serverError.config.method}}</span>
              <span class='form-text'>{{serverError.config.url}}</span>
            </div>
            <div v-if='serverError.config.data' class='pt-2 server-error'>
              <span class='form-label fw-bold pe-2'>Data:</span>
              <span class='form-text'>{{serverError.config.data}}</span>
            </div>
          </div>
          <div v-if='serverError && serverError.stack' class='pt-2 server-error'>
            <span class='form-label fw-bold pe-2'>Stack:</span>
            <span class='form-text'>{{serverError.stack}}</span>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary btn-rounded btn-red btn-burn-area-modal-conditions" data-bs-dismiss="modal">OK</button>
        </div>
      </div>
    </div>
  </div>
  <div id='subscribeSuccessModal' class="modal fade" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header model-header-success p-2 ps-3 pe-3">
          <h5 class="modal-title">Subscribe Succeeded!</h5>
          <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class='form-label fw-bold'>Your subscription has been added.</div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary btn-rounded btn-green btn-burn-area-modal-conditions" data-bs-dismiss="modal">OK</button>
        </div>
      </div>
    </div>
  </div>
  <div id='subscribeWarningModal' class="modal fade" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header modal-header-warning p-2 ps-3 pe-3">
          <h5 class="modal-title">Subscribe Failed!</h5>
          <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class='form-label'>
            <span>This Burn Unit could not be found.</span>
          </div>
          <div class='form-label'>
            <span>Subscription is not available.</span>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary btn-rounded btn-red btn-burn-area-modal-conditions" data-bs-dismiss="modal">OK</button>
        </div>
      </div>
    </div>
  </div>
  <div class='container-fluid mt-2'>
    <div v-if="!mySubscribedBurn" class='card'>
      <div class='card-header form-label fw-bold'>Confirm Subscription</div>
      <div v-if='burn' class='card-body'>
        <div class='row'>
          <div class='col'>
            <div class='card-title form-label'>By clicking the 'Subscribe' button, you agree that you wish to receive automated email notifications for this burn unit:</div>
          </div>
          <div class='col'>
            <div class='card card-body'>
              <div class='row'>
                <div class='col form-label fw-bold'>Burn Id:</div>
                <div class='col'>{{burn.id}}</div>
              </div>
              <div class='row'>
                <div class='col form-label fw-bold'>Burn Name:</div>
                <div class='col'>{{burn.name}}</div>
              </div>
              <div class='row'>
                <div class='col form-label fw-bold'>Locality:</div>
                <div class='col'>{{burn.locality}}</div>
              </div>
              <div class='row'>
                <div class='col form-label fw-bold'>State:</div>
                <div class='col'>{{burn.state}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class='card-footer'>
        <button type="button" class="btn btn-primary btn-rounded btn-green btn-burn-area-modal" @click="onClickSubscribe()">Subscribe</button>
      </div>
    </div>
    <div v-else class='card'>
      <div class='card-header form-label fw-bold'>Subscription Confirmed!</div>
      <div class='card-body'>
        <div class='row'>
          <div class='col'>
            <div class='card-title form-label'>You have successfully subscribed to burn unit {{mySubscribedBurn.id}} - {{mySubscribedBurn.name}}!</div>
          </div>
          <div class='col'>
            <div class='card card-body'>
              <div class='row'>
                <div class='col form-label fw-bold'>Burn Id:</div>
                <div class='col'>{{burn.id}}</div>
              </div>
              <div class='row'>
                <div class='col form-label fw-bold'>Burn Name:</div>
                <div class='col'>{{burn.name}}</div>
              </div>
              <div class='row'>
                <div class='col form-label fw-bold'>Locality:</div>
                <div class='col'>{{burn.locality}}</div>
              </div>
              <div class='row'>
                <div class='col form-label fw-bold'>State:</div>
                <div class='col'>{{burn.state}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-lonely-if */
import { mapState } from 'vuex';
import { Modal } from 'bootstrap';

export default {
  name: 'Subscribe',
  mixins: [],
  props: {},
  components: [],
  computed: {
    ...mapState('BurnArea', {
      mySubscribedBurn: (state) => state.mySubscribedBurn,
      serverError: (state) => state.serverError,
    }),
  },
  data() {
    console.debug('Enter data()');

    const data = {
      burn: null,
      formLoading: false,
      recipient: null,
      subscribeErrorModal: null,
      subscribeSuccessModal: null,
      subscribeWarningModal: null,
    };
    console.debug('Data: ', data);

    console.debug('Exit data()');
    return data;
  },
  mounted() {
    console.debug('Enter mounted()');

    console.debug('$route.params: ', this.$route.params);

    this.burn = JSON.parse(atob(decodeURIComponent(this.$route.params.burn)));
    console.debug('this.burn: ', this.burn);

    this.recipient = JSON.parse(atob(decodeURIComponent(this.$route.params.recipient)));
    console.debug('this.recipient: ', this.recipient);

    this.subscribeErrorModal = new Modal('#subscribeErrorModal');
    this.subscribeSuccessModal = new Modal('#subscribeSuccessModal');
    this.subscribeWarningModal = new Modal('#subscribeWarningModal');

    console.debug('this: ', this);

    console.debug('Exit mounted()');
  },
  methods: {
    onClickSubscribe() {
      console.debug('Enter onClickSubscribe()');

      console.debug('this.burn: ', this.burn);
      console.debug('this.recipient: ', this.recipient);

      const model = {
        burnId: this.burn.id,
        name: this.recipient.name,
        email: this.recipient.email,
      };
      console.debug('model: ', model);

      this.$store.dispatch('BurnArea/postBurnSubscription', model).then(() => {
        if (this.serverError === null) {
          this.subscribeSuccessModal.show();
        } else {
          if (this.serverError.message.includes('404')) {
            this.subscribeWarningModal.show();
          } else {
            this.subscribeErrorModal.show();
          }
        }
      }).catch((exception) => {
        console.error('Enter BurnArea/postBurnSubscription.catch()');
        console.error('exception: ', exception);
        console.error('Exit BurnArea/postBurnSubscription.catch()');
      });

      console.debug('Exit onClickSubscribe()');
    },
  },
};
</script>
