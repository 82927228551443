// import { createRouter, createWebHashHistory } from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';
import BurnArea from '../views/BurnArea.vue';
import BurnAreas from '../views/BurnAreas.vue';
import BurnRepeater from '../components/BurnRepeater.vue';
import Home from '../views/Home.vue';
import SignIn from '../views/SignIn.vue';
import SignOut from '../views/SignOut.vue';
import SignUp from '../views/SignUp.vue';
import Subscribe from '../views/Subscribe.vue';
import Unsubscribe from '../views/Unsubscribe.vue';

const routes = [
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  // {
  //   path: '/admin',
  //   name: 'Admin',
  //   component: () => import(/* webpackChunkName: "admin" */ '../views/Admin.vue'),
  // },
  {
    path: '/burnarea/:id',
    name: 'BurnArea',
    component: BurnArea,
  },
  {
    path: '/burnareas',
    name: 'BurnAreas',
    component: BurnAreas,
    children: [
      {
        path: 'myactiveburns',
        name: 'MyActiveBurns',
        component: BurnRepeater,
        props: { type: 'activeBurns' },
      },
      {
        path: 'myreadytoburns',
        name: 'MyReadyToBurns',
        component: BurnRepeater,
        props: { type: 'readyToBurns' },
      },
      {
        path: 'mypastburns',
        name: 'MyPastBurns',
        component: BurnRepeater,
        props: { type: 'pastBurns' },
      },
    ],
  },
  {
    path: '/burnmap',
    name: 'BurnMap',
    component: () => import(/* webpackChunkName: "burnmap" */ '../views/BurnMap.vue'),
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/signin',
    name: 'SignIn',
    component: SignIn,
  },
  {
    path: '/signout',
    name: 'SignOut',
    component: SignOut,
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUp,
  },
  {
    path: '/subscribe/:burn/:recipient',
    name: 'Subscribe',
    component: Subscribe,
  },
  {
    path: '/unsubscribe/:burn/:recipient',
    name: 'Unsubscribe',
    component: Unsubscribe,
  },
];

const router = createRouter({
  history: createWebHistory(),
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {

  if (to.name === 'SignUp') {
    store.commit('App/setShowNavbarContainer', false);
  } else {
    store.commit('App/setShowNavbarContainer', true);
  }

  const auth = store.getters['App/getAuth'];

  if (!auth.isUserSignedIn()
    && to.name !== 'Contact'
    && to.name !== 'Home'
    && to.name !== 'SignIn'
    && to.name !== 'SignOut'
    && to.name !== 'SignUp'
    && to.name !== 'Subscribe'
    && to.name !== 'Unsubscribe'
  ) {
    store.commit('Navigation/setTo', to);
    store.commit('Navigation/setFrom', from);
    auth.getSession();
  } else {
    store.commit('Navigation/setCurrent', to);
    next();
  }

});

export default router;
