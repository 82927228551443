/* eslint-disable import/no-cycle, object-shorthand, padded-blocks */
/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import axios from 'axios';
import { api } from '@/api';
import store from '@/store';

const preferredConditionsMetadata = [
  {
    name: 'temperature',
    label: 'Temperature (F)',
    type: Number,
    step: 1,
    valType: 'range',
    value: null,
    required: false,
    position: 0,
  },
  {
    name: 'relativeHumidity',
    label: 'Relative Humidity (%)',
    type: Number,
    step: 1,
    valType: 'range',
    value: null,
    required: false,
    position: 1,
  },
  {
    name: 'windSpeed',
    label: 'Wind Speed (mph)',
    type: Number,
    step: 1,
    valType: 'range',
    value: { min: null, max: null },
    required: false,
    position: 2,
  },
  {
    name: 'windDirection',
    label: 'Wind Direction',
    type: Array,
    valType: 'direction',
    value: [],
    options: ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'],
    required: true,
    position: 3,
  },
  {
    name: 'windGust',
    label: 'Wind Gust (mph)',
    type: Number,
    step: 1,
    valType: 'range',
    value: { min: null, max: null },
    required: false,
    position: 4,
  },
  {
    name: 'quantitativePrecipitation',
    label: 'Quantitative Precipitation (in)',
    type: Number,
    step: 0.01,
    valType: 'range',
    value: { min: null, max: null },
    required: false,
    position: 5,
  },
  {
    name: 'transportWindSpeed',
    label: 'Transport Wind Speed (mph)',
    type: Number,
    step: 1,
    valType: 'range',
    value: { min: null, max: null },
    required: false,
    position: 6,
  },
  {
    name: 'transportWindDirection',
    label: 'Transport Wind Direction',
    type: Array,
    valType: 'direction',
    value: [],
    options: ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'],
    required: false,
    position: 7,
  },
  {
    name: 'dispersionIndex',
    label: 'Dispersion Index (DI)',
    type: Number,
    step: 1,
    valType: 'range',
    value: { min: null, max: null },
    required: false,
    position: 8,
  },
  {
    name: 'mixingHeight',
    label: 'Mixing Height (ft)',
    type: Number,
    step: 1,
    valType: 'range',
    value: { min: null, max: null },
    required: false,
    position: 9,
  },
];

export const namespaced = true;

export const state = {
  geocoderConfig: null,
  myBurn: {},
  myBurns: [],
  myBurnStats: [],
  myNewBurn: null,
  mySubscribedBurn: null,
  myUnsubscribedBurn: null,
  myNotifications: { dailyEmail: false, subscribers: [] },
  myStats: [],
  pageSize: 5,
  geocodeResults: null,
  reverseGeocodeResult: null,
  searchString: '',
  serverError: null,
};

export const getters = {
  getGeocodeResults: (state) => {
    return state.geocodeResults;
  },
  getPreferredConditionsMetadata: () => {
    return preferredConditionsMetadata;
  },
};

export const actions = {
  deleteBurn({ commit }, id) {
    commit('setServerError', null);
    return api.delete(`/burnarea/${id}`).then(() => {}).catch((exception) => {
      console.error('Enter burnArea.deleteBurn.catch()');
      console.error('exception: ', exception);
      commit('setServerError', exception);
      console.error('Exit burnArea.deleteBurn.catch()');
    });
  },
  fetchGeocodeResults({ commit }, singleLine) {
    commit('setServerError', null);
    const geocoderConfig = store.getters['App/getGeocoderConfig'];
    const url = `${geocoderConfig.url}findAddressCandidates?category=City,Subregion,Postal,YX&SourceCountry=US&inSR=4326&outSR=102100&outFields=City,Subregion,Region&SingleLine=${singleLine}&f=json`;
    return axios.get(url).then((response) => {
      return commit('setGeocodeResults', response.data);
    }).catch((exception) => {
      console.error('Enter burnArea.fetchGeocodeResults.catch()');
      console.error('exception: ', exception);
      console.error('Exit burnArea.fetchGeocodeResults.catch()');
      return commit('setServerError', exception);
    });
  },
  getBurn({ commit }, id) {
    console.debug('Enter getBurn()');
    console.debug(`id: ${id}`);
    commit('setServerError', null);
    console.debug('Exit getBurn()');
    return api.get(`/burnarea/${id}`).then((response) => {
      const myBurn = { ...response.data };
      const preferredConditions = preferredConditionsMetadata.map((pcmd) => {
        let preferredCondition = myBurn.fields.preferredConditions.find((pc) => pc.name === pcmd.name);
        if (!preferredCondition) {
          preferredCondition = { ...pcmd };
        }
        if (!preferredCondition.value) {
          preferredCondition.value = { ...pcmd.value };
        }
        preferredCondition.valType = pcmd.valType;
        preferredCondition.position = pcmd.position;
        switch (preferredCondition.valType) {
          case 'direction':
            if (!Array.isArray(preferredCondition.value)) {
              preferredCondition.value = [];
            }
            break;
          case 'range':
            if (typeof preferredCondition.value.min === 'undefined' || typeof preferredCondition.value.min === 'undefined') {
              preferredCondition.value = { min: null, max: null };
            }
            break;
          default:
            break;
        }
        return preferredCondition;
      }).sort((a, b) => a.position < b.position);
      myBurn.fields.preferredConditions = preferredConditions;
      return commit('setMyBurn', myBurn);
    }).catch((exception) => {
      console.error('Enter burnArea.getBurn.catch()');
      console.error('exception: ', exception);
      console.error('Exit burnArea.getBurn.catch()');
      return commit('setServerError', exception);
    });
  },
  getBurns({ commit }) {
    console.debug('Enter getBurns()');
    commit('setServerError', null);
    console.debug('Exit getBurns()');
    return api.get('/burnarea').then((response) => {
      const myBurns = response.data.map((e) => {
        const myBurn = { ...e };
        const preferredConditions = preferredConditionsMetadata.map((pcmd) => {
          let preferredCondition = myBurn.fields.preferredConditions.find((pc) => pc.name === pcmd.name);
          if (!preferredCondition) {
            preferredCondition = { ...pcmd };
          }
          if (!preferredCondition.value) {
            preferredCondition.value = { ...pcmd.value };
          }
          preferredCondition.valType = pcmd.valType;
          preferredCondition.position = pcmd.position;
          switch (preferredCondition.valType) {
            case 'direction':
              if (!Array.isArray(preferredCondition.value)) {
                preferredCondition.value = [];
              }
              break;
            case 'range':
              if (typeof preferredCondition.value.min === 'undefined' || typeof preferredCondition.value.min === 'undefined') {
                preferredCondition.value = { min: null, max: null };
              }
              break;
            default:
              break;
          }
          return preferredCondition;
        }).sort((a, b) => a.position < b.position);
        myBurn.fields.preferredConditions = preferredConditions;
        return myBurn;
      });
      return commit('setMyBurns', myBurns);
    }).catch((exception) => {
      console.error('Enter burnArea.getBurns.catch()');
      console.error('exception: ', exception);
      console.error('Exit burnArea.getBurns.catch()');
      return commit('setServerError', exception);
    });
  },
  getBurnStats({ commit }, id) {
    console.debug('Enter getBurnStats()');
    console.debug(`id: ${id}`);
    commit('setServerError', null);
    console.debug('Exit getBurnStats()');
    return api.get(`/stat/burnarea/${id}`).then((response) => {
      const myBurnStats = response.data.map((e) => {
        const myBurnStat = { ...e };
        myBurnStat.fields.weatherConditions = preferredConditionsMetadata.map((pcmd) => {
          let weatherCondition = e.fields.weatherConditions.find((wc) => wc.name === pcmd.name);
          if (!weatherCondition) {
            weatherCondition = {
              name: pcmd.name,
              values: [],
            };
          }
          weatherCondition.position = pcmd.position;
          return weatherCondition;
        }).sort((a, b) => a.position < b.position);
        return myBurnStat;
      });
      return commit('setMyBurnStats', myBurnStats);
    }).catch((exception) => {
      console.error('Enter burnArea.getBurnStats.catch()');
      console.error('exception: ', exception);
      console.error('Exit burnArea.getBurnStats.catch()');
      return commit('setServerError', exception);
    });
  },
  getActiveBurns({ commit }) {
    console.debug('Enter getActiveBurns()');
    commit('setServerError', null);
    console.debug('Exit getActiveBurns()');
    return api.get('/burnarea/active').then((response) => {
      const myBurns = response.data.map((e) => {
        const myBurn = { ...e };
        const preferredConditions = preferredConditionsMetadata.map((pcmd) => {
          let preferredCondition = myBurn.fields.preferredConditions.find((pc) => pc.name === pcmd.name);
          if (!preferredCondition) {
            preferredCondition = { ...pcmd };
          }
          if (!preferredCondition.value) {
            preferredCondition.value = { ...pcmd.value };
          }
          preferredCondition.valType = pcmd.valType;
          preferredCondition.position = pcmd.position;
          switch (preferredCondition.valType) {
            case 'direction':
              if (!Array.isArray(preferredCondition.value)) {
                preferredCondition.value = [];
              }
              break;
            case 'range':
              if (typeof preferredCondition.value.min === 'undefined' || typeof preferredCondition.value.min === 'undefined') {
                preferredCondition.value = { min: null, max: null };
              }
              break;
            default:
              break;
          }
          return preferredCondition;
        }).sort((a, b) => a.position < b.position);
        myBurn.fields.preferredConditions = preferredConditions;
        return myBurn;
      });
      console.debug('myBurns: ', myBurns);
      return commit('setMyBurns', myBurns);
    }).catch((exception) => {
      console.error('Enter burnArea.getActiveBurns.catch()');
      console.error('exception: ', exception);
      console.error('Exit burnArea.getActiveBurns.catch()');
      return commit('setServerError', exception);
    });
  },
  getInactiveBurns({ commit }) {
    console.debug('Enter getInactiveBurns()');
    commit('setServerError', null);
    console.debug('Exit getInactiveBurns()');
    return api.get('/burnarea/inactive').then((response) => {
      const myBurns = response.data.map((e) => {
        const myBurn = { ...e };
        const preferredConditions = preferredConditionsMetadata.map((pcmd) => {
          let preferredCondition = myBurn.fields.preferredConditions.find((pc) => pc.name === pcmd.name);
          if (!preferredCondition) {
            preferredCondition = { ...pcmd };
          }
          if (!preferredCondition.value) {
            preferredCondition.value = { ...pcmd.value };
          }
          preferredCondition.valType = pcmd.valType;
          preferredCondition.position = pcmd.position;
          switch (preferredCondition.valType) {
            case 'direction':
              if (!Array.isArray(preferredCondition.value)) {
                preferredCondition.value = [];
              }
              break;
            case 'range':
              if (typeof preferredCondition.value.min === 'undefined' || typeof preferredCondition.value.min === 'undefined') {
                preferredCondition.value = { min: null, max: null };
              }
              break;
            default:
              break;
          }
          return preferredCondition;
        }).sort((a, b) => a.position < b.position);
        myBurn.fields.preferredConditions = preferredConditions;
        return myBurn;
      });
      return commit('setMyBurns', myBurns);
    }).catch((exception) => {
      console.error('Enter burnArea.getInactiveBurns.catch()');
      console.error('exception: ', exception);
      console.error('Exit burnArea.getInactiveBurns.catch()');
      return commit('setServerError', exception);
    });
  },
  getReverseGeocodeResult({ commit }, coordinates) {
    commit('setServerError', null);
    const geocoderConfig = store.getters['App/getGeocoderConfig'];
    return axios.get(`${geocoderConfig.url}reverseGeocode?f=pjson&location=${coordinates[0]},${coordinates[1]}`).then((response) => {
      return commit('setReverseGeocodeResult', response.data);
    }).catch((exception) => {
      console.error('Enter burnArea.getReverseGeocodeResult.catch()');
      console.error('exception: ', exception);
      console.error('Exit burnArea.getReverseGeocodeResult.catch()');
      return commit('setServerError', exception);
    });
  },
  getStats({ commit }) {
    console.debug('Enter getStats()');
    commit('setServerError', null);
    console.debug('Exit getStats()');
    return api.get('/stat/').then((response) => {
      const myBurnStats = response.data.map((e) => {
        const myBurnStat = { ...e };
        myBurnStat.fields.weatherConditions = preferredConditionsMetadata.map((pcmd) => {
          let weatherCondition = e.fields.weatherConditions.find((wc) => wc.name === pcmd.name);
          if (weatherCondition) {
            weatherCondition.valType = pcmd.valType;
            weatherCondition.position = pcmd.position;
          } else {
            weatherCondition = {
              name: pcmd.name,
              values: [],
              valType: pcmd.valType,
              position: pcmd.position,
            };
          }
          return weatherCondition;
        }).sort((a, b) => a.position < b.position);
        return myBurnStat;
      });
      return commit('setMyStats', myBurnStats);
    }).catch((exception) => {
      console.error('Enter burnArea.getStats.catch()');
      console.error('exception: ', exception);
      console.error('Exit burnArea.getStats.catch()');
      return commit('setServerError', exception);
    });
  },
  postBurn({ commit }, model) {
    console.debug('Enter postBurn()');
    commit('setServerError', null);
    console.debug('Exit postBurn()');
    return api.post('/burnarea/', model).then((response) => {
      return commit('setMyNewBurn', response.data);
    }).catch((exception) => {
      console.error('Enter burnArea.postBurn.catch()');
      console.error('exception: ', exception);
      console.error('Exit burnArea.postBurn.catch()');
      return commit('setServerError', exception);
    });
  },
  putBurn({ commit }, model) {
    console.debug('Enter putBurn()');
    commit('setServerError', null);
    console.debug('Exit putBurn()');
    return api.put('/burnarea/', model).then((response) => {
      return commit('setMyBurn', response.data);
    }).catch((exception) => {
      console.error('Enter burnArea.postBurn.catch()');
      console.error('exception: ', exception);
      console.error('Exit burnArea.postBurn.catch()');
      return commit('setServerError', exception);
    });
  },
  postBurnSubscription({ commit }, model) {
    console.debug('Enter postBurnSubscription()');
    commit('setServerError', null);
    console.debug('Exit postBurnSubscription()');
    return api.post('/burnarea/subscribe', model).then((response) => {
      return commit('setMySubscribedBurn', response.data);
    }).catch((exception) => {
      console.error('Enter burnArea.postBurnSubscription.catch()');
      console.debug('exception: ', JSON.stringify(exception));
      console.error('Exit burnArea.postBurnSubscription.catch()');
      return commit('setServerError', exception);
    });
  },
  deleteBurnSubscription({ commit }, model) {
    console.debug('Enter deleteBurnSubscription()');
    commit('setServerError', null);
    console.debug('Exit deleteBurnSubscription()');
    return api.put('/burnarea/unsubscribe', model).then((response) => {
      return commit('setMyUnsubscribedBurn', response.data);
    }).catch((exception) => {
      console.error('Enter burnArea.postBurnSubscription.catch()');
      console.debug('exception: ', JSON.stringify(exception));
      console.error('Exit burnArea.postBurnSubscription.catch()');
      return commit('setServerError', exception);
    });
  },
};

export const mutations = {
  setGeocoderConfig(state, data) {
    state.geocoderConfig = data;
  },
  setGeocodeResults(state, geocodeResults) {
    state.geocodeResults = geocodeResults;
  },
  setMyBurn(state, data) {
    state.myBurn = data;
  },
  setMyBurns(state, data) {
    state.myBurns = data.sort((a, b) => a.name.localeCompare(b.name));
  },
  setMyBurnStats(state, data) {
    state.myBurnStats = data;
  },
  setMyNewBurn(state, data) {
    state.myNewBurn = data;
  },
  setMySubscribedBurn(state, data) {
    state.mySubscribedBurn = data;
  },
  setMyUnsubscribedBurn(state, data) {
    state.myUnsubscribedBurn = data;
  },
  setMyStats(state, data) {
    state.myStats = data;
  },
  setPageSize(state, value) {
    state.pageSize = value;
  },
  setReverseGeocodeResult(state, reverseGeocodeResult) {
    state.reverseGeocodeResult = reverseGeocodeResult;
  },
  setSearchString(state, searchString) {
    state.searchString = searchString;
  },
  setServerError(state, serverError) {
    state.serverError = serverError;
  },
};
