<style lang="scss" scoped>
  .link-edit {
    color: rgb(127, 63, 20) !important;
  }
</style>
<template>
  <div v-if='myBurn && myBurn.fields'>
    <div class='card-body mt-0 pt-0'>
      <div class='row'>
        <div class='col'>
          <div class='card-title'>
            <span class='form-label fw-bold me-2'>Daily Email:</span>
            <span class='form-text'>{{ dailyEmail }}</span>
          </div>
        </div>
        <div class='col-1 text-end'>
          <a class='btn p-0 m-0 link-edit fw-bold' role='button' v-on:click='$emit("editBurnAreaNotifications", $event)'>Edit</a>
        </div>
      </div>
      <div class='row'>
        <div class='col'>
          <div class='card-title'>
            <span class='form-label fw-bold me-2'>Subscribers:</span>
            <span class='form-text'>{{ subscribers }}</span>
          </div>
        </div>
        <div class='col-1 text-end'>
          <a class='btn p-0 m-0 link-edit fw-bold' role='button' v-on:click='onClickAdd($emit, $event)'>Add</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
import { mapState } from 'vuex';

export default {
  name: 'BurnAreaDetails',
  props: {
    id: Number,
  },
  emits: ['addBurnAreaSubscriber', 'editBurnAreaNotifications'],
  components: {},
  computed: {
    ...mapState('BurnArea', {
      myBurn: (state) => state.myBurn,
      subscribers() {
        if (!this.myBurn || !this.myBurn.fields || !this.myBurn.fields.recipients) return '';
        const subscribers = this.myBurn.fields.recipients.map((e) => e.name).sort((a, b) => a.localeCompare(b));
        return subscribers.join(', ');
      },
    }),
    dailyEmail() {
      if (!this.myBurn.fields.dailyEmail) return 'Off';
      return 'On';
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    onClickAdd(emit, event) {
      console.debug('Enter onClickAdd()');

      console.debug('emit: ', emit);
      console.debug('event: ', event);

      emit('addBurnAreaSubscriber', event);

      console.debug('Exit onClickAdd()');
    },
  },
};
</script>


