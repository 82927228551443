/* eslint-disable object-shorthand, padded-blocks */
/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import { api } from '@/api';

// https://stackoverflow.com/questions/67125889/how-to-resolve-vue-dependency-cycle-with-axios-api-services-and-vuex
export const namespaced = true;

export const state = {
  serverError: null,
  user: null,
  userRegistrationModel: null,
};

export const actions = {
  getUser({ commit }) {
    return api.get('/user').then((response) => {
      commit('setUser', response.data);
    }).catch((exception) => {
      console.error('Enter user.getUser.catch()');
      console.error('exception: ', exception);
      commit('setServerError', exception);
      console.error('Exit user.getUser.catch()');
    });
  },
  putUser({ commit }, user) {
    return api.put('/user', user).then((response) => {
      commit('setUser', response.data);
    }).catch((exception) => {
      console.error('Enter user.putUser.catch()');
      console.error('exception: ', exception);
      commit('setServerError', exception);
      console.error('Exit user.putUser.catch()');
    });
  },
  postUserRegistrationModel({ commit }, userRegistrationModel) {
    return api.post('/user/register', userRegistrationModel).then((response) => {
      commit('setUserRegistrationModel', response.data);
    }).catch((exception) => {
      console.error('Enter user.postUserRegistrationModel.catch()');
      console.error('exception: ', exception);
      commit('setServerError', exception);
      console.error('Exit user.postuserRegistrationModel.catch()');
    });
  },
};

export const mutations = {
  setServerError(state, data) {
    state.serverError = data;
  },
  setUser(state, data) {
    if (!data.fields || data.fields == null) {
      /* eslint-disable-next-line */
      data.fields = {};
    }
    state.user = data;
  },
  setUserRegistrationModel(state, data) {
    state.userRegistrationModel = data;
  },
};
