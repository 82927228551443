/* eslint-disable arrow-parens, max-len, padded-blocks */
import axios from 'axios';

const options = {
  auth: null,
};

const api = axios.create({
  baseURL: '/api',
});

api.interceptors.request.use(async (config) => {
  // console.debug('Enter axios request interceptor');
  // console.debug('options.auth: ', options.auth);
  // const token = localStorage.getItem('pbp:jwtToken');
  const token = options.auth.getSignInUserSession().getIdToken().jwtToken;
  /*  eslint-disable-next-line */
  config.headers.Authorization = `Bearer ${token}`;
  // console.debug('Exit axios request interceptor');
  return config;
});

api.interceptors.response.use(
  (response) => {
    // console.debug('Enter axios response interceptor success handler');
    // console.debug('response: ', response);
    // console.debug('options.auth: ', options.auth);
    // if (options.auth !== null) {
    //   console.debug('options.auth.getUsername(): ', options.auth.getUsername());
    //   const signInUserSession = options.auth.getSignInUserSession();
    //   console.debug('signInUserSession: ', signInUserSession);
    //   const idToken = signInUserSession.getIdToken();
    //   console.debug('idToken: ', idToken);
    //   const jwtToken = idToken.jwtToken;
    //   console.debug('jwtToken: ', jwtToken);
    //   const refreshToken = signInUserSession.getRefreshToken();
    //   console.debug('refreshToken: ', refreshToken);
    // }
    // console.debug('Exit axios response interceptor success handler');
    return response;
  },
  async (error) => {
    console.debug('Enter axios response interceptor error handler');
    console.error('error: ', error);
    console.debug('options.auth: ', options.auth);
    if (options.auth !== null) {
      console.debug('options.auth.getUsername(): ', options.auth.getUsername());
    }
    console.debug('Enter axios response interceptor error handler');
    return Promise.reject(error);
  },
);

// export default api;
export { api, options };
