export default {
  watch: {},
  methods: {
    getBurnConditionClass(burnScore) {

      if (!burnScore.score) {
        console.warn('Required burnScore property \'score\' not set!');
        return null;
      }

      const score = burnScore.score;

      if (score.requiredPreferredConditionsSet !== score.requiredPreferredConditionsMet) return { 'burn-condition-score-low': true };
      if (score.optionalPreferredConditionsMet === 0) return { 'burn-condition-score-medium': true };

      const percentageMet = (score.requiredPreferredConditionsMet + score.optionalPreferredConditionsMet) / (score.requiredPreferredConditionsSet + score.optionalPreferredConditionsSet);

      const itemClass = {
        'burn-condition-score-low': percentageMet < 0.2,
        'burn-condition-score-medium': percentageMet >= 0.2 && percentageMet < 0.6,
        'burn-condition-score-high': percentageMet >= 0.6,
      };

      return itemClass;
    },
    getBurnConditionScore(burnCondition, preferredConditions) {

      // console.debug('burnCondition: ', burnCondition);
      // console.debug('preferredConditions: ', preferredConditions);

      const preferredConditionsMetadata = this.$store.getters['BurnArea/getPreferredConditionsMetadata'];
      const requiredPreferredConditionFieldNames = preferredConditionsMetadata.filter((e) => e.required === true).map((e) => e.name);

      const requiredPreferredConditions = preferredConditions.filter(
        (e) => requiredPreferredConditionFieldNames.includes(e.name),
      ).map((e) => {
        const preferredCondition = { ...e };
        preferredCondition.valType = preferredConditionsMetadata.find((p) => p.name === e.name).valType;
        return preferredCondition;
      });

      const requiredPreferredConditionsSet = requiredPreferredConditions.filter(
        (e) => this.isPreferredConditionSet(e),
      );

      const requiredPreferredConditionsMet = requiredPreferredConditionsSet.filter((e) => {
        const weatherCondition = burnCondition.weatherConditions.find((w) => w.name === e.name);
        if (!weatherCondition) return false;
        return this.isPreferredConditionMet(e, weatherCondition);
      });
      // console.debug('requiredPreferredConditionsMet: ', requiredPreferredConditionsMet);

      const optionalPreferredConditionFieldNames = preferredConditionsMetadata.filter((e) => e.required !== true).map((e) => e.name);
      // console.debug('optionalPreferredConditionFieldNames: ', optionalPreferredConditionFieldNames);

      const optionalPreferredConditions = preferredConditions.filter(
        (e) => optionalPreferredConditionFieldNames.includes(e.name),
      ).map((e) => {
        const preferredCondition = { ...e };
        preferredCondition.valType = preferredConditionsMetadata.find((p) => p.name === e.name).valType;
        return preferredCondition;
      });

      const optionalPreferredConditionsSet = optionalPreferredConditions.filter((e) => this.isPreferredConditionSet(e));

      const optionalPreferredConditionsMet = optionalPreferredConditionsSet.filter((e) => {
        const weatherCondition = burnCondition.weatherConditions.find((w) => w.name === e.name);
        if (!weatherCondition) return false;
        return this.isPreferredConditionMet(e, weatherCondition);
      });
      // console.debug('optionalPreferredConditionsMet: ', optionalPreferredConditionsMet);

      const burnConditionScore = {
        requiredPreferredConditionsSet: requiredPreferredConditionsSet.length,
        requiredPreferredConditionsMet: requiredPreferredConditionsMet.length,
        optionalPreferredConditionsSet: optionalPreferredConditionsSet.length,
        optionalPreferredConditionsMet: optionalPreferredConditionsMet.length,
      };

      return burnConditionScore;
    },
    isBurnAreaReadyToBurn(burnArea) {

      if (!burnArea.burnScores) return false;

      let scores = burnArea.burnScores.filter(
        (e) => e.score,
      ).map(
        (e) => e.score,
      );

      scores = scores.filter(
        (e) => (e.requiredPreferredConditionsMet === e.requiredPreferredConditionsSet) && (e.optionalPreferredConditionsSet > 0),
      ).map((e) => {
        return (e.requiredPreferredConditionsMet + e.optionalPreferredConditionsMet) / (e.requiredPreferredConditionsSet + e.optionalPreferredConditionsSet);
      }).filter((e) => e >= 0.60);

      return scores.length > 0;
    },
  },
};
