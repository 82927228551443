/* eslint-disable object-shorthand, padded-blocks */
/* eslint no-shadow: ["error", { "allow": ["state"] }] */
export const namespaced = true;

export const state = {
  mySearches: [],
};

export const getters = {
  getMySearches: (state) => {
    state.mySearches = (JSON.parse(localStorage.getItem('pbp:searches')) || []).sort((a, b) => b.id - a.id);
    return state.mySearches;
  },
};

export const mutations = {
  addMySearch(state, search) {
    state.mySearches = (JSON.parse(localStorage.getItem('pbp:searches')) || []).filter((e) => e.value.toUpperCase() !== search.toUpperCase());
    state.mySearches.push({
      id: new Date().getTime(),
      value: search,
    });
    state.mySearches = state.mySearches.sort((a, b) => b.id - a.id);
    if (state.mySearches.length > 5) {
      state.mySearches = state.mySearches.slice(0, 5);
    }
    localStorage.setItem('pbp:searches', JSON.stringify(state.mySearches));
  },
};
