<style lang="scss" scoped>
  .border-custom{
    border-color: rgb(151, 151, 151)!important;
    border-width: 1px !important;
  }
  .btn {
    color: white;
    font-weight: bold;
  }
  .burn-condition-met {
    background-color: rgb(128, 145, 59);
  }
  .burn-condition-score-not-applicable {
    color: black;
    background-color: gray;
    font-size: 0.75em;
  }
  .burn-condition-score-low {
    color: black;
    background-color: rgb(184, 49, 49);
    font-size: 0.75em;
  }
  .burn-condition-score-medium {
    color: black;
    background-color: rgb(255, 234, 112);
    font-size: 0.75em;
  }
  .burn-condition-score-high {
    color: black;
    background-color: rgb(128, 145, 59);
    font-size: 0.75em;
  }
  .col-header-light {
    background-color: rgb(231, 231, 231);
  }
</style>

<template>
  <div v-if='myBurn && weatherConditions && conditionsMetadata' class='shadow'>

    <div class='row border-bottom border-end border-custom'>
      <div class='col-5 border-start border-custom' style='background-color: rgb(192, 192, 192);'><span>&#xA0;</span></div>
      <div v-for='(weekday, index) in weekdays' :key='weekday.dayNumber' class='col border-start border-custom' :class='getBurnConditionHeaderClass(index)'>
        {{getBurnConditionHeaderText(index)}}
      </div>
    </div>

    <div class='row border-bottom border-end border-custom'>
      <div class='col-3 border-start border-custom fw-bold col-header-light p-1 ps-3'>Conditions</div>
      <div class='col-2 border-start border-custom fw-bold col-header-light p-1 ps-3'>Preferred Conditions</div>
      <div v-for='weekday in weekdays' :key='weekday.dayNumber' class='col border-start border-custom fw-bold col-header-light p-1 ps-3'>{{weekday.dayName}}. {{weekday.dayNumber}}</div>
    </div>

    <div v-if='weatherConditions && weatherConditions.length > 0'>
      <div v-for='preferredCondition in myBurn.fields.preferredConditions' :key='preferredCondition.name' class='row border-bottom border-end border-custom'>
        <div class='col-3 border-start border-custom p-1 ps-3'>{{ getPreferredConditionLabel(preferredCondition.name) }}</div>
        <div class='col-2 border-start border-custom p-1 ps-3'>{{ getPreferredConditionValue(preferredCondition) }}</div>
        <div v-for='burnCondition in getPreferredConditionBurnConditions(preferredCondition)' :key='burnCondition.date' class='col border-start border-custom p-1 ps-3' :class="{'burn-condition-met' : isPreferredConditionMet(preferredCondition, burnCondition), 'burn-condition-not-applicable' : preferredCondition.value === null }">
          {{ getBurnConditionValue(burnCondition) }}
        </div>
      </div>
    </div>
    <div v-else>
      <div v-for='preferredCondition in myBurn.fields.preferredConditions' :key='preferredCondition.name' class='row border-bottom border-end border-custom'>
        <div class='col-3 border-start border-custom p-1 ps-3'>{{ getPreferredConditionLabel(preferredCondition.name) }}</div>
        <div class='col-2 border-start border-custom p-1 ps-3'>{{ getPreferredConditionValue(preferredCondition) }}</div>
        <div v-for='item in [0, 1, 2, 3, 4, 5, 6]' :key='item' class='col border-start border-custom p-1 ps-3'>N/A</div>
      </div>
    </div>

  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
import { mapState } from 'vuex';
import BurnAreaHelper from '@/mixins/burnAreaHelper';
import BurnScoreHelper from '@/mixins/burnScoreHelper';
import DateHelper from '@/mixins/dateHelper';
import DirectionHelper from '@/mixins/directionHelper';
import PreferredConditionHelper from '@/mixins/preferredConditionHelper';

export default {
  name: 'BurnAreaConditions',
  mixins: [
    BurnAreaHelper,
    BurnScoreHelper,
    DateHelper,
    DirectionHelper,
    PreferredConditionHelper,
  ],
  props: {
    id: Number,
  },
  emits: ['editBurnAreaConditions'],
  components: {},
  computed: {
    ...mapState('BurnArea', {
      myBurn: (state) => state.myBurn,
      myBurnStats: (state) => state.myBurnStats,
    }),
  },
  data() {
    const weatherConditions = [];
    const weekdays = [{}, {}, {}, {}, {}, {}, {}].map((item, index) => {
      const date = new Date(new Date().setDate(new Date().getDate() + index));
      return {
        item,
        date,
        dayName: date.toLocaleDateString('en-US', { weekday: 'short' }),
        dayNumber: date.getDate(),
      };
    });
    return {
      conditionsMetadata: this.$store.getters['BurnArea/getPreferredConditionsMetadata'],
      weatherConditions,
      weekdays,
    };
  },
  mounted() {
    this.$store.dispatch('BurnArea/getBurnStats', this.id).then(() => {
      if (this.myBurnStats && this.myBurnStats.length > 0) {
        this.weatherConditions = this.myBurnStats[0].fields.weatherConditions;
      }
    });
  },
};
</script>
