export default {
  data() {
    return {
      burnConditions: [],
    };
  },
  watch: {
    /* eslint-disable-next-line */
    myBurn: function() {
      console.debug('Enter watch.myBurn()');

      this.formLoading = true;
      this.setMyBurnScores();
      this.formLoading = false;

      console.debug('Exit watch.myBurn()');
    },
    /* eslint-disable-next-line */
    myBurnStats: function() {
      console.debug('Enter watch.myBurnStats()');

      this.formLoading = true;
      this.setMyBurnScores();
      this.formLoading = false;

      console.debug('Exit watch.myBurnStats()');
    },
  },
  methods: {
    getBurnConditionHeaderClass(index) {

      if (!this.myBurn || !this.myBurnStats) return {};

      const preferredConditionsMetadata = this.$store.getters['BurnArea/getPreferredConditionsMetadata'];

      const burnConditions = this.weatherConditions.map((e) => {
        const preferredConditionMetadata = preferredConditionsMetadata.find((p) => p.name === e.name);
        const preferredCondition = this.myBurn.fields.preferredConditions.find((p) => p.name === e.name);
        preferredCondition.valType = preferredConditionMetadata.valType;
        preferredCondition.required = preferredConditionMetadata.required;
        const burnCondition = { ...e.values[index] };
        burnCondition.valType = e.valType;
        return {
          preferredCondition,
          burnCondition,
        };
      });

      const requiredBurnConditionsSet = burnConditions.filter((e) => e.preferredCondition.required);
      const requiredBurnConditionsMet = requiredBurnConditionsSet.filter((e) => this.isPreferredConditionMet(e.preferredCondition, e.burnCondition));
      if (requiredBurnConditionsSet.length !== requiredBurnConditionsMet.length) return { 'burn-condition-score-low': true };

      const optionalBurnConditionsSet = burnConditions.filter((e) => e.preferredCondition.required !== true && this.isPreferredConditionSet(e.preferredCondition));
      const optionalBurnConditionsMet = optionalBurnConditionsSet.filter((e) => this.isPreferredConditionMet(e.preferredCondition, e.burnCondition));
      if (optionalBurnConditionsMet.length === 0) return { 'burn-condition-score-medium': true };

      const percentageMet = (requiredBurnConditionsMet.length + optionalBurnConditionsMet.length) / (requiredBurnConditionsSet.length + optionalBurnConditionsSet.length);

      const headerClass = {
        'burn-condition-score-low': percentageMet < 0.2,
        'burn-condition-score-medium': percentageMet >= 0.2 && percentageMet < 0.6,
        'burn-condition-score-high': percentageMet >= 0.6,
      };

      return headerClass;
    },
    getBurnConditionHeaderText(index) {

      if (!this.myBurn || !this.myBurnStats) return '';

      const preferredConditionsMetadata = this.$store.getters['BurnArea/getPreferredConditionsMetadata'];

      const burnConditions = this.weatherConditions.map((e) => {
        const preferredConditionMetadata = preferredConditionsMetadata.find((p) => p.name === e.name);
        const preferredCondition = this.myBurn.fields.preferredConditions.find((p) => p.name === e.name);
        preferredCondition.valType = preferredConditionMetadata.valType;
        preferredCondition.required = preferredConditionMetadata.required;
        const burnCondition = { ...e.values[index] };
        burnCondition.valType = e.valType;
        return {
          preferredCondition,
          burnCondition,
        };
      });

      const requiredBurnConditionsSet = burnConditions.filter((e) => e.preferredCondition.required);
      const requiredBurnConditionsMet = requiredBurnConditionsSet.filter((e) => this.isPreferredConditionMet(e.preferredCondition, e.burnCondition));
      const optionalBurnConditionsSet = burnConditions.filter((e) => e.preferredCondition.required !== true && this.isPreferredConditionSet(e.preferredCondition));
      const optionalBurnConditionsMet = optionalBurnConditionsSet.filter((e) => this.isPreferredConditionMet(e.preferredCondition, e.burnCondition));

      const percentageMet = (requiredBurnConditionsMet.length + optionalBurnConditionsMet.length) / (requiredBurnConditionsSet.length + optionalBurnConditionsSet.length);
      if (Number.isNaN(percentageMet)) return '';

      return `${(percentageMet * 100).toFixed(2)}%`;
    },
    getBurnConditionValue(burnCondition) {

      if (burnCondition.value === undefined || burnCondition.value === null || burnCondition.value === '') return 'N/A';

      if (burnCondition.valType === 'cumulative') {
        if (burnCondition.value === undefined || burnCondition.value === null) return 'N/A';
        const displayValue = `${(burnCondition.value * 0.0393701).toFixed(2)} in`;
        return displayValue;
      }

      if (burnCondition.valType === 'direction') {
        const displayValue = `${this.getBurnConditionDirection(burnCondition)} (${burnCondition.value.toFixed(2)}°)`;
        return displayValue;
      }

      if (burnCondition.valType === 'range') {

        if (burnCondition.value.min === undefined || burnCondition.value.min === null) return 'N/A';
        if (burnCondition.value.max === undefined || burnCondition.value.max === null) return 'N/A';

        if (burnCondition.name === 'temperature') {
          const displayValue = `${(burnCondition.value.max * (9 / 5) + 32).toFixed(2)}°`;
          return displayValue;
        }
        if (burnCondition.name === 'relativeHumidity') {
          const displayValue = `${burnCondition.value.min.toFixed(2)}%`;
          return displayValue;
        }
        if (burnCondition.name === 'windSpeed') {
          const displayValue = `${(burnCondition.value.avg * (0.621371)).toFixed(2)} mph`;
          return displayValue;
        }
        if (burnCondition.name === 'windGust') {
          const displayValue = `${(burnCondition.value.avg * (0.621371)).toFixed(2)} mph`;
          return displayValue;
        }
        if (burnCondition.name === 'transportWindSpeed') {
          const displayValue = `${(burnCondition.value.avg * (0.621371)).toFixed(2)} mph`;
          return displayValue;
        }
        if (burnCondition.name === 'dispersionIndex') {
          const displayValue = `${(burnCondition.value.avg).toFixed(2)}`;
          return displayValue;
        }
        if (burnCondition.name === 'mixingHeight') {
          const displayValue = `${(burnCondition.value.avg * (3.28084)).toFixed(2)} ft`;
          return displayValue;
        }
        return burnCondition.value.avg;
      }

      return 'N/A';
    },
    getPreferredConditionBurnConditions(preferredCondition) {

      const burnConditionRoot = this.weatherConditions.find((e) => e.name === preferredCondition.name);

      let burnConditions = burnConditionRoot.values.map(
        (e) => (
          { name: burnConditionRoot.name, date: e.startDate, valType: burnConditionRoot.valType, value: e.value }
        ),
      );

      if (burnConditions && burnConditions.length === 0) {
        console.warn(`The weather forecast data set does not contain any items for preferred condition '${preferredCondition.name}'`);
        burnConditions = this.weekdays.map(
          (e) => (
            { name: burnConditionRoot.name, date: e.date, valType: burnConditionRoot.valType, value: null }
          ),
        );
      }

      return burnConditions;
    },
    getPreferredConditionLabel(name) {
      let label = '';
      const conditionMetadata = this.conditionsMetadata.find((e) => e.name === name);
      if (conditionMetadata) label = conditionMetadata.label;
      return label;
    },
    getPreferredConditionValue(preferredCondition) {

      if (preferredCondition.value === undefined || preferredCondition.value === null) return 'N/A';

      const metaData = this.conditionsMetadata.find((e) => e.name === preferredCondition.name);

      if (metaData.valType === 'direction') {
        if (preferredCondition.value === null || (Array.isArray(preferredCondition.value) && preferredCondition.value.length === 0)) return 'N/A';
        return preferredCondition.value.join(', ');
      }

      if (metaData.valType === 'range') {
        if (preferredCondition.value.min === null || preferredCondition.value.min === '') return 'N/A';
        if (preferredCondition.value.max === null || preferredCondition.value.max === '') return 'N/A';
        return `${preferredCondition.value.min} - ${preferredCondition.value.max}`;
      }

      return preferredCondition.value;
    },
    setMyBurnScores() {

      if (!this.myBurnStats || this.myBurnStats.length === 0) return;

      const preferredConditionsMetadata = this.$store.getters['BurnArea/getPreferredConditionsMetadata'];
      const requiredPreferredConditionFieldNames = preferredConditionsMetadata.filter((e) => e.required === true).map((e) => e.name);

      const requiredPreferredConditions = this.myBurn.fields.preferredConditions.filter(
        (e) => requiredPreferredConditionFieldNames.includes(e.name),
      ).map((e) => {
        const preferredCondition = { ...e };
        preferredCondition.valType = preferredConditionsMetadata.find((p) => p.name === e.name).valType;
        return preferredCondition;
      });

      const requiredPreferredConditionsSet = requiredPreferredConditions.filter(
        (e) => this.isPreferredConditionSet(e),
      );

      const optionalPreferredConditionFieldNames = preferredConditionsMetadata.filter((e) => e.required !== true).map((e) => e.name);

      const optionalPreferredConditions = this.myBurn.fields.preferredConditions.filter(
        (e) => optionalPreferredConditionFieldNames.includes(e.name),
      ).map((e) => {
        const preferredCondition = { ...e };
        preferredCondition.valType = preferredConditionsMetadata.find((p) => p.name === e.name).valType;
        return preferredCondition;
      });

      const optionalPreferredConditionsSet = optionalPreferredConditions.filter(
        (e) => this.isPreferredConditionSet(e),
      );

      const myBurnStat = this.myBurnStats.sort((a, b) => a.created.localeCompare(b.created))[0];

      const weatherConditionDates = this.getWeatherConditionDates(myBurnStat);

      const weatherConditions = myBurnStat.fields.weatherConditions.map((weatherCondition) => weatherCondition.values).flat().map((e) => {
        const weatherCondition = { ...e };
        weatherCondition.preferredCondition = this.myBurn.fields.preferredConditions.find((p) => p.name === e.name);
        weatherCondition.preferredConditionMet = this.isPreferredConditionMet(weatherCondition.preferredCondition, weatherCondition);
        return weatherCondition;
      });

      this.burnConditions = weatherConditionDates.map((e) => {

        const burnConditionStartDate = e.startDate;

        const burnConditionWeatherConditions = weatherConditions.filter(
          (weatherCondition) => {
            const weatherConditionStartDate = weatherCondition.startDate;
            return new Date(weatherConditionStartDate).valueOf() === burnConditionStartDate.valueOf();
          },
        );

        const score = {
          requiredPreferredConditionsSet: requiredPreferredConditionsSet.length,
          requiredPreferredConditionsMet: burnConditionWeatherConditions.filter((w) => w.preferredCondition.required === true && w.preferredConditionMet === true).length,
          optionalPreferredConditionsSet: optionalPreferredConditionsSet.length,
          optionalPreferredConditionsMet: burnConditionWeatherConditions.filter((w) => w.preferredCondition.required === false && w.preferredConditionMet === true).length,
        };

        return {
          startDate: e.startDate,
          endDate: e.endDate,
          weatherConditions: burnConditionWeatherConditions,
          score,
          readyToBurn: this.isScoreReadyToBurn(score),
        };
      });

      this.readyToBurn = (this.burnConditions.filter((e) => e.readyToBurn === true).length > 0);
    },
  },
};
