/* eslint-disable object-shorthand, padded-blocks */
/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import { api, options } from '@/api';

export const namespaced = true;

export const state = {
  version: 0,
  build: 1,
  auth: null,
  authConfig: null,
  burnMapConfig: null,
  burnUnitMapConfig: null,
  geocoderConfig: null,
  serverError: null,
  showNavbarContainer: true,
  states: null,
};

export const getters = {
  getAuth: (state) => {
    return state.auth;
  },
  getAuthConfig: (state) => {
    state.authConfig = JSON.parse(localStorage.getItem('pbp:authConfig'));
    return state.authConfig;
  },
  getGeocoderConfig: (state) => {
    return state.geocoderConfig;
  },
};

export const actions = {
  fetchBurnMapConfig: ({ commit }) => {
    return api.get('/app/burnmap').then((response) => {
      commit('setBurnMapConfig', response.data);
    }).catch((exception) => {
      commit('setServerError', exception);
    });
  },
  fetchBurnUnitMapConfig: ({ commit }) => {
    return api.get('/app/burnunitmap').then((response) => {
      commit('setBurnUnitMapConfig', response.data);
    }).catch((exception) => {
      commit('setServerError', exception);
    });
  },
  fetchGeocoderConfig: ({ commit }) => {
    return api.get('/app/geocoder').then((response) => {
      commit('setGeocoderConfig', response.data);
    }).catch((exception) => {
      commit('setServerError', exception);
    });
  },
  fetchStates: ({ commit }) => {
    return api.get('/app/states').then((response) => {
      commit('setStates', response.data);
    }).catch((exception) => {
      commit('setServerError', exception);
    });
  },
};

export const mutations = {
  setAuth(state, value) {
    state.auth = value;
    options.auth = value;
  },
  setBurnMapConfig(state, burnMapConfig) {
    state.burnMapConfig = burnMapConfig;
  },
  setBurnUnitMapConfig(state, burnUnitMapConfig) {
    state.burnUnitMapConfig = burnUnitMapConfig;
  },
  setGeocoderConfig(state, geocoderConfig) {
    state.geocoderConfig = geocoderConfig;
  },
  setShowNavbarContainer(state, showNavbarContainer) {
    state.showNavbarContainer = showNavbarContainer;
  },
  setServerError(state, serverError) {
    state.serverError = serverError;
  },
  setStates(state, states) {
    state.states = states.states.sort((a, b) => {
      const aName = a.name.toUpperCase();
      const bName = b.name.toUpperCase();
      if (aName < bName) {
        return -1;
      }
      if (aName > bName) {
        return 1;
      }
      return 0;
    });
  },
};
