export default {
  data() {
    const directions = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'].map((e, n) => {
      return {
        name: e,
        min: (45 * n) - (45 / 2) < 0 ? (45 * n) - (45 / 2) + 360 : (45 * n) - (45 / 2),
        max: (45 * n) + (45 / 2),
      };
    });
    // const directions = ['N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW'].map((e, n) => {
    //   return {
    //     name: e,
    //     min: (22.5 * n) - (22.5 / 2) < 0 ? (22.5 * n) - (22.5 / 2) + 360 : (22.5 * n) - (22.5 / 2),
    //     max: (22.5 * n) + (22.5 / 2),
    //   };
    // });
    return {
      directions,
    };
  },
  methods: {
    getBurnConditionDirection(burnCondition) {
      if (!burnCondition.value) return null;
      let direction = this.directions.find((e) => burnCondition.value >= e.min && burnCondition.value < e.max);
      if (!direction) direction = this.directions[0];
      return direction.name;
    },
    getWeatherConditionDirection(weatherCondition) {
      if (!weatherCondition.value) return null;
      let direction = this.directions.find((e) => weatherCondition.value >= e.min && weatherCondition.value < e.max);
      if (!direction) direction = this.directions[0];
      return direction.name;
    },
  },
};
