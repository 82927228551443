export default {
  data() {
    return {
      // burnConditionDates: this.getBurnConditionDates(),
    };
  },
  methods: {
    getWeatherConditionDates(burnStat) {
      // console.debug('Enter getWeatherConditionDates()');

      let weatherConditionDates = [];

      if (burnStat && burnStat.fields && burnStat.fields.weatherConditions) {
        weatherConditionDates = burnStat.fields.weatherConditions[0].values.map(
          (e) => ({ startDate: new Date(e.startDate), endDate: new Date(e.endDate) }),
        );
      } else {
        console.warn('burnStat contains no weather conditions!');
        console.warn('burnStat: ', burnStat);
      }

      // console.debug('weatherConditionDates: ', weatherConditionDates);

      // console.debug('Exit getWeatherConditionDates()');
      return weatherConditionDates;
    },
    // getBurnConditionDates() {
    //   // console.debug('Enter getBurnConditionDates()');

    //   // 2021-09-23T04:00:00.000Z

    //   let now = new Date();
    //   now = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);

    //   const burnConditionDates = [0, 1, 2, 3, 4, 5, 6].map((e) => {

    //     let startDate = new Date();
    //     startDate.setDate(now.getDate() + e);
    //     startDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0, 0);

    //     this.getGMTDate(startDate);

    //     const endDate = new Date(startDate);
    //     endDate.setDate(startDate.getDate() + 1);

    //     this.getGMTDate(startDate);

    //     return {
    //       startDate: this.getGMTDate(startDate),
    //       endDate: this.getGMTDate(endDate),
    //     };

    //     // return {
    //     //   startDate,
    //     //   endDate,
    //     // };
    //   });

    //   // console.debug('burnConditionDates: ', burnConditionDates);

    //   // console.debug('Exit getBurnConditionDates()');
    //   return burnConditionDates;
    // },
    // getGMTDate(dateTime) {
    //   // console.debug('Enter getGMTDate()');

    //   // 2021-09-23T04:00:00.000Z
    //   // 2021-09-23T00:00:00.000Z

    //   let month = (dateTime.getMonth() + 1).toString();
    //   while (month.length < 2) {
    //     month = `0${month}`;
    //   }

    //   let date = (dateTime.getDate()).toString();
    //   while (date.length < 2) {
    //     date = `0${date}`;
    //   }

    //   const gmtString = `${dateTime.getFullYear()}-${month}-${date}T00:00:00.000Z`;
    //   // console.debug('gmtString: ', gmtString);

    //   const gmtDate = new Date(Date.parse(gmtString));
    //   // console.debug('gmtDate: ', gmtDate);

    //   // console.debug('Exit getGMTDate()');
    //   return gmtDate;
    // },
  },
};
