<style lang="scss" scoped>
  .link-edit {
    color: rgb(127, 63, 20) !important;
  }
</style>
<template>
  <div>
    <div v-if="myBurn && myBurn !== null && myBurn.fields" class='card-body mb-0 pb-0'>
      <div class='row'>
        <div class='col'>
          <div class='card-title'>
            <span class='form-label fw-bold me-2'>Name:</span>
            <span class='form-text'>{{ myBurn.name }}</span>
          </div>
        </div>
        <div class='col-1 text-end'>
          <a class='btn p-0 m-0 link-edit fw-bold' role='button' v-on:click='$emit("editBurnAreaDetails", $event)'>Edit</a>
        </div>
      </div>
      <div class='row'>
        <div class='col'>
          <div class='card-title'>
            <span class='form-label fw-bold me-2'>Date Created:</span>
            <span class='form-text'>{{ new Date(myBurn.created).toLocaleDateString() }}</span>
          </div>
        </div>
        <div class='col'>
          <div v-if='myBurn.fields.burnDate' class='card-title'>
            <span class='form-label fw-bold me-2'>Date Last Burned:</span>
            <span class='form-text'>{{ myBurn.fields.burnDate ? new Date(myBurn.fields.burnDate).toLocaleDateString() : ''}}</span>
          </div>
        </div>
        <div class='col-1'/>
      </div>
      <div class='row'>
        <div class='col'>
          <div class='card-title'>
            <span class='form-label fw-bold me-2'>Burn Window:</span>
            <span class='form-text'>{{ myBurn.fields.burnWindow }}</span>
          </div>
        </div>
        <div class='col'>
          <div class='card-title'>
            <span class='form-label fw-bold me-2'>Planned Acres:</span>
            <span class='form-text'>{{ myBurn.fields.plannedAcres }}</span>
          </div>
        </div>
        <div class='col-1'/>
      </div>
      <div class='row'>
        <div class='col'>
          <div class='card-title'>
            <span class='form-label fw-bold me-2'>Locality:</span>
            <span class='form-text'>{{ myBurn.fields.locality }}</span>
          </div>
        </div>
        <div class='col'>
          <div class='card-title'>
            <span class='form-label fw-bold me-2'>State:</span>
            <span class='form-text'>{{ myBurn.fields.state }}</span>
          </div>
        </div>
        <div class='col-1'/>
      </div>
      <div class='row'>
        <div class='col'>
          <div class='card-title'>
            <span class='form-label fw-bold me-2'>Burn Status:</span>
            <span class='form-text'>{{ myBurn.fields.burnStatus }}</span>
          </div>
        </div>
        <div class='col'>
          <div class='card-title'>
            <span class='form-label fw-bold me-2'>Priority:</span>
            <span class='form-text'>{{ myBurn.fields.priority }}</span>
          </div>
        </div>
        <div class='col-1'/>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
import { mapState } from 'vuex';

export default {
  name: 'BurnAreaDetails',
  props: {
    id: Number,
  },
  emits: ['editBurnAreaDetails'],
  components: {
  },
  computed: {
    ...mapState('BurnArea', {
      myBurn: (state) => state.myBurn,
    }),
    burnWindow() {
      if (!this.myBurn.fields.burnWindowStart || this.myBurn.fields.burnWindowStart == null) {
        return '';
      }
      if (!this.myBurn.fields.burnWindowEnd || this.myBurn.fields.burnWindowEnd == null) {
        return '';
      }
      const burnWindowStartDate = new Date(this.myBurn.fields.burnWindowStart);
      const burnWindowEndDate = new Date(this.myBurn.fields.burnWindowEnd);
      const burnWindowStart = `${(burnWindowStartDate.getMonth() + 1)}/${burnWindowStartDate.getDate()}`;
      const burnWindowEnd = `${(burnWindowEndDate.getMonth() + 1)}/${burnWindowEndDate.getDate()}`;
      return `${burnWindowStart} - ${burnWindowEnd}`;
    },
  },
  data() {
    return {};
  },
  mounted() {
    // console.debug('Enter BurnAreaDetails.mounted()');
    // console.debug('this.myBurn: ', this.myBurn);
    // console.debug('Exit BurnAreaDetails.mounted()');
  },
  methods: {},
};
</script>

