export default {
  methods: {
    isScoreReadyToBurn(score) {
      // console.debug('Enter isScoreReadyToBurn()');

      // console.debug('score: ', score);

      if (score.requiredPreferredConditionsSet !== score.requiredPreferredConditionsMet) return false;
      if (score.optionalPreferredConditionsMet === 0) return false;

      const percentageMet = (score.requiredPreferredConditionsMet + score.optionalPreferredConditionsMet) / (score.requiredPreferredConditionsSet + score.optionalPreferredConditionsSet);
      // console.debug('percentageMet: ', percentageMet);

      // console.debug('Exit isScoreReadyToBurn()');
      return percentageMet >= 0.60;
    },
  },
};
