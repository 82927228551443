<style lang="scss" scoped>
  .btn-burn-row {
    height:100%;
    width:100%;
  }
  .btn-search {
    cursor: default;
  }
  .form-check-input:checked {
      background-color: rgb(127, 63, 20)!important;
      border-color: rgb(164, 141, 41)!important;
  }
  .form-check-input:focus {
    border-color: rgba(166, 143, 37);
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(166, 143, 37, 0.25);
  }
  .form-check-label {
    font-size: .75em;
    font-weight: bold;
  }
  .form-control:focus, .form-control:hover {
    color: #212529;
    background-color: #fff;
    border-color: rgba(166, 143, 37, 0.25);
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(166, 143, 37, 0.25);
  }
  .form-switch {
    display: inline-block !important;
    padding-top: 0px;
  }
  .lbl-burn {
    font-size: .75em;
    font-weight: bold;
    width: 100%;
  }
  .router-link-exact-active {
      color: #000;
      background-color: rgb(216, 216, 216);
      border-color: #6c757d;
  }
  .search {
    .border {
      border-radius: 20px;
    }
  }
  .search input {
    border: none;
    font-size: .75em;
  }
</style>
<template>
  <div class="container-fluid">
    <div class="container-fluid mt-3">Burn List</div>
    <div class='row align-items-center'>
      <div class="col d-flex align-items-center">
        <div class="row btn-burn-row m-0 ms-2">
          <div class='col p-0'>
            <router-link to="myactiveburns" class='btn btn-outline-secondary lbl-burn' @click='onClickRouterLink()'>My Active Burns</router-link>
          </div>
          <div class='col p-0'>
            <router-link to="myreadytoburns" class='btn btn-outline-secondary lbl-burn'>Ready to Burn</router-link>
          </div>
          <div class='col p-0'>
            <router-link to="mypastburns" class='btn btn-outline-secondary lbl-burn'>My Inactive Burns</router-link>
          </div>
        </div>
      </div>
      <div class='col d-flex align-items-center justify-content-center'>
        <label class="form-check-label me-2 text-nowrap text-truncate" for="flexSwitchCheckDefault">All Daily Emails for Active Burns:</label>
        <span class="form-check form-switch">
          <input v-if='user' v-model='user.fields.dailyEmail' class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" @change='onDailyEmailChange'>
        </span>
      </div>
      <div class="col search d-flex align-items-end">
        <div class="border input-group">
          <input type="search" class="form-control ms-3" placeholder="Search by Burn Unit Name, Locality, or Priority" :value="searchString"  @input="onInputSearchString($event.target.value)"/>
          <span class='btn btn-search'>
            <font-awesome-icon icon="search" />
          </span>
        </div>
      </div>
    </div>
  </div>
  <router-view />
</template>

<script>
import { ref } from 'vue';
import { mapState, useStore } from 'vuex';

export default {
  name: 'BurnAreas',
  components: {},
  computed: {
    ...mapState('User', {
      user: (state) => state.user,
    }),
  },
  mounted() {
    this.$store.dispatch('User/getUser').then(() => {
      //
    });
  },
  methods: {
    onClickRouterLink() {
      // this.searchString = '';
    },
    onDailyEmailChange() {
      this.$store.dispatch('User/putUser', this.user);
    },
  },
  setup() {
    const store = useStore();
    const searchString = ref('');
    let searchStringTimeoutHandle = null;
    function onInputSearchString(value) {
      clearTimeout(searchStringTimeoutHandle);
      searchStringTimeoutHandle = setTimeout(
        () => {
          searchString.value = value;
          store.commit('BurnArea/setSearchString', searchString.value);
        },
        1000,
      );
    }
    return { searchString, onInputSearchString };
  },
};
</script>
